
export const classifyList = [
    {
        id: 6,
        imageUrl: require("../../../assets/index/huoma.png"),
        title: "渠道活码",
        url: "/channelCode/index",
        permission: 'system:9250c'
    },
    {
        id: 7,
        imageUrl: require("../../../assets/index/sucai.png"),
        title: "素材库",
        url: "/medium/index",
        permission: 'system:X65Ib'
    },
    {
        id: 10,
        imageUrl: require("../../../assets/index/daiban.png"),
        title: "待办事项",
        url: "/index/index",
        permission: ''
    },
    {
        id: 4,
        imageUrl: require("../../../assets/index/del.png"),
        title: "删人提醒",
        url: "/contactDeleteRemind/index",
        permission: 'system:E0Pss'
    },
    {
        id: 2,
        imageUrl: require("../../../assets/index/laqun.png"),
        title: "一键拉群",
        url: "/roomTemplatePull/index",
        size: { width: "24px", height: "20px" },
        permission: ''
    },
    // {
    //   id: 6,
    //   imageUrl: require("../../../assets/index/huoma.png"),
    //   title: "渠道活码",
    //   url: "/channelCode/index",
    // },
    // {
    //   id: 8,
    //   imageUrl: require("../../../assets/index/qunfa.png"),
    //   title: "客户群发",
    //   url: "/contactMessageBatchSend/create",
    //   size: { width: "21px", height: "16px" },
    // },
    {
        id: 9,
        imageUrl: require("../../../assets/index/huanyingyu.png"),
        title: "欢迎语",
        url: "/contactWelcome/index",
        permission: 'system:JV9B6'
    },
    // {
    //   id: 9,
    //   imageUrl: require("../../../assets/index/qunfa2.png"),
    //   title: "客户群群发",
    //   url: "/roomMessageBatchSend/create",
    //   size: { width: "24px", height: "18px" },
    // },
    {
        id: 11,
        imageUrl: require("../../../assets/index/choujiang.png"),
        title: "分享抽奖",
        url: "/shareDraw/index",
        permission: 'system:T0xRO'
    },
    {
        id: 10,
        imageUrl: require("../../../assets/index/radar.png"),
        title: "雷达",
        url: "/contactRadar/index",
        size: { width: "21px", height: "21px" },
        permission: 'system:t9Buh'
    },
    {
        id: 5,
        imageUrl: require("../../../assets/index/liushi.png"),
        title: "流失提醒",
        url: "/contactLost/index",
        permission: 'system:E0Pss'
    },
    {
        id: 3,
        imageUrl: require("../../../assets/index/pengyouquan.png"),
        title: "朋友圈",
        url: "/electronicCard/circle",
        permission: 'system:xov6q'
    },
    {
        id: 4,
        imageUrl: require("../../../assets/index/tool.png"),
        title: "客户统计",
        url: "/contactStatistic/index",
        permission: 'system:ofbnr'
    },
    {
        id: 12,
        imageUrl: require("../../../assets/index/poster.png"),
        title: "生成海报",
        url: "/poster/index",
        permission: ''
    },
    // {
    //   id: 13,
    //   imageUrl: require("../../../assets/index/qunfa3.png"),
    //   title: "群发",
    //   url: "",
    // },
    {
        id: 16,
        imageUrl: require("../../../assets/index/kehulist.png"),
        title: "客户列表",
        url: "/contact/index",
        permission: 'system:zSTgX'
    },
    // {
    //   id: 14,
    //   imageUrl: require("../../../assets/index/sop.png"),
    //   title: "SOP",
    //   // url: "/contactRadar/index",
    // },
    // {
    //   id: 16,
    //   imageUrl: require("../../../assets/index/renwubao.png"),
    //   title: "企微任务宝",
    //   // url: "/contactRadar/index",
    // },
    {
        id: 14,
        imageUrl: require("../../../assets/index/shequn.png"),
        title: "话术中心",
        url: "/socialinteraction/index",
        permission: 'system:MvevR'
    },
    {
        id: 15,
        imageUrl: require("../../../assets/index/yuyue.png"),
        title: "预约处理",
        url: "/appointment/index",
        permission: 'system:Vp6nf'
    },
    {
        id: 13,
        imageUrl: require("../../../assets/index/qunfa3.png"),
        title: "群发",
        url: "/qunfa/index",
        permission: 'system:Aw1Jv,system:ptLDc,system:xov6q'
    },
    {
        id: 16,
        imageUrl: require("../../../assets/index/hexiao.png"),
        title: "兑奖核销",
        url: "/lottery/index",
        permission: ''
    },
    {
        id: 17,
        imageUrl: require("../../../assets/index/sop.png"),
        title: "SOP",
        url: "/sopList/index",
        size: { width: "19px", height: "14px" },
        permission: 'system:7u29x'
    },
    {
        id: 18,
        imageUrl: require("../../../assets/workOrder/index.png"),
        title: "工单管理",
        url: "/workOrder/index",
        size: { width: "18px", height: "16px" },
        permission: 'system:workmenage'
    },
]