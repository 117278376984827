<template>
  <div class="index">
    <van-nav-bar title="筛选客户" left-text="返回" left-arrow @click-left="onClickLeft" />
    <!--        @click-left="onClickLeft"-->
    <!--        @click-right="onClickRight"-->
    <div class="content">
      <div class="item">
        <div class="list-item">
          <div class="item-title">
            所属客服
          </div>
          <div @click="$refs.employee.$show(StandbyStaffList,'max')" class="item-content">

            {{ StandbyStaffList.length > 0 ? String(StandbyStaff) : '请选择客服' }}
            <van-icon name="arrow-down" />
          </div>
        </div>
        <div class="list-item">
          <div class="item-title">
            企业标签
          </div>
          <!-- <div @click="$refs.tags.$show(tagsList)" style="margin-top: 10px;">
            <van-button @click="" style="height: 30px;" plain type="info">选择标签</van-button>
          </div> -->
          <div class="lableRow">
            <div @click="lableBtn(0,tagsList)" :class="{qd: enterpriseLabel === 0}" class="item-content item-lable"
            style="  padding: 0 26px;">
            全部
          </div>
            <div @click="lableBtn(2,tagsList)" :class="{qd: enterpriseLabel === 2}" class="item-content item-lable"
            style="  padding: 0 26px;">
            选择标签
           </div>
           <div @click="lableBtn(1,tagsList)" :class="{qd: enterpriseLabel === 1}" class="item-content item-lable"
           style="  padding: 0 26px;">
            未打标签
          </div>

            <!-- <div @click="$refs.tags.$show(tagsList)" style="margin-top: 10px;">
              <van-button @click="" style="height: 30px;" plain type="info">选择标签</van-button>
            </div>
            <div @click="$refs.tags.$show(tagsList)" style="margin-top: 10px;">
              <van-button @click="" style="height: 30px;" plain type="info">选择标签</van-button>
            </div> -->
          </div>
          
          <div v-if="tagsList.length > 0" style="margin-top: 10px;">
            <van-tag style="margin: 0 5px" v-for="(item,index) in tagsList" closeable plain size="medium" type="primary"
              @close="tagsList.splice(index,1)">{{ item.name }}</van-tag>
          </div>
        </div>
        <div class="list-item">
          <div class="item-title">
            添加时间
          </div>
          <div class="item-content" >
            <div @click="$refs.time.$show('start')" style="flex: 1;text-align: center">{{ sTime ? sTime : '开始时间' }}</div>
            -
            <div @click="$refs.time.$show('end')" style="flex: 1;text-align: center">{{ endTime ? endTime : '结束时间' }}</div>
          </div>
        </div>
        <div class="list-item">
          <div class="item-title">
            添加渠道
          </div>
          <div>
            <div v-for="(item,index) in qcode" :key="index" @click="activeq(index)" :class="{qd: item.chose}"
              class="item-content item-lable">
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="list-item">
          <div class="item-title">
            客户性别
          </div>
          <div>
            <div @click="codew = ''" :class="{qd: codew === ''}" class="item-content item-lable"
              style="  padding: 0 26px;">
              全部
            </div>
            <div @click="codew = 0" :class="{qd: codew === 0}" class="item-content item-lable"
              style="  padding: 0 26px;">
              未知
            </div>
            <div @click="codew = 1" :class="{qd: codew == 1}" class="item-content item-lable"
              style="   padding: 0 26px;">
              男
            </div>
            <div @click="codew = 2" :class="{qd: codew == 2}" class="item-content item-lable"
              style="   padding: 0 26px;">
              女
            </div>
          </div>
        </div>
        <div class="list-item">
          <div class="item-title">
            对公业务
          </div>
          <div>
            <div @click="publicBusiness = ''" :class="{qd: publicBusiness === ''}" class="item-content item-lable">
              全部
            </div>
            <div @click="publicBusiness = 0" :class="{qd: publicBusiness === 0}" class="item-content item-lable">
              个人客户
            </div>
            <div @click="publicBusiness= 1" :class="{qd: publicBusiness == 1}" class="item-content item-lable">
              企业客户
            </div>
          </div>
        </div>
        <!-- <div class="list-item">
          <div class="item-title">
            流失状态
          </div>
          <div>
            <div @click="lose = ''" :class="{qd: lose == ''}" class="item-content item-lable">
              全部
            </div>
            <div @click="lose = 1" :class="{qd: lose == 1}" class="item-content item-lable">
              未流失
            </div>
            <div @click="lose = 2" :class="{qd: lose == 2}" class="item-content item-lable">
              已流失
            </div>
            
          </div>
        </div> -->
        <div style="justify-content: space-around;
        width: 100%;display: flex;    justify-content: space-between;">
          <van-button @click="onClickLeft" style="width: 35%;" block type="default">取消</van-button>
          <van-button @click="customer" style="width: 35%;" block type="info">确定</van-button>
        </div>
      </div>

    </div>
    <!--  选择客户  -->
    <addCustomer ref="customer" />
    <!--  选择标签  -->
    <addTags ref="tags" @change="tagsChose" />
    <!--  时间选择  -->
    <addTime ref="time" @change="choseTime"/>

    <employeeIndex ref="employee" @change="userChange" />
  </div>
</template>

<script>
  import addCustomer from "../index/components/addCustomer";
  import addTags from "../channelCode/components/customerLabel";
  import employeeIndex from "../channelCode/components/employeeIndex";
  import addTime from "../channelCode/components/addTime1";

  export default {
    components: {
      addCustomer,
      addTags,
      addTime,
      employeeIndex
    },
    data() {
      return {
        // 文字
        message: '',
        // tbas切换
        tabsChose: 0,
        // 员工
        StandbyStaffList: [],
        StandbyStaff: [],
        tagsList: [],
        sTime: '',
        endTime: '',

        //渠道
        qcode: [
          { chose: true, addWay: '', name: '全部' }, 
          { chose: false, addWay: 1, name: '二维码添加' }, 
          { chose: false, addWay: 2, name: '搜索手机号' }, 
          // { chose: false, addWay: 5, name: '手机通讯录' }, 
          // { chose: false, addWay: 8, name: '安装第三方应用时添加的客服人员' }, 
          { chose: false, addWay: 202, name: '管理员/负责人分配' }, 
          { chose: false, addWay: 1003, name: '裂变引流' },
        ],
        // 性别
        codew: '',
        lose: '',
        chose: '',
        enterpriseLabel:0,
        publicBusiness: ''
      }
    },
    watch: {},
    methods: {
      //企业标签切换
      lableBtn(type,data){
        this.enterpriseLabel=type
        if(type===2){
          this.$refs.tags.$show(data)
        }else{
          this.tagsList=[]//清空选择标签的数据
        }
      },
      onClickLeft() {
        this.$router.back()
      },
      // 员工
      userChange(data, info) {
        this.StandbyStaffList = data
        this.StandbyStaff = data.map(i => i.name)
      },
      tagsChose(data) {
        this.tagsList = data
      },
      choseTime(data) {
        data.type==='start'?this.sTime = data.time: this.endTime =data.time
        // if(data.type==='start'){
        //   this.sTime = data.time;
        // }
        // this.sTime = data.time.split("-")[0]
        // this.endTime = data.time.split("-")[1]
      },
      activeq(i) {
        this.qcode.forEach(i => {
          return i.chose = false
        })
        this.qcode[i].chose = true
        this.chose = this.qcode[i].addWay
      },
      customer() {
        if (this.StandbyStaffList.length == 0) {
          this.$notify('请选择客服');
          return
        }
        // 判断开始时间是否大于结束时间  begin
        let startTmp = this.sTime.split("-");
        let endTmp = this.endTime.split("-");
        let stT = new Date(startTmp[0], startTmp[1], startTmp[2]);
        let edT = new Date(endTmp[0], endTmp[1], endTmp[2]);
        if (stT.getTime() >= edT.getTime()) {
              this.$notify('日期有误！请重新选择');
            return
        }
        const arr = []
        this.tagsList.map(item => {
          arr.push(item.id)
        })
        const brr = []
        this.StandbyStaffList.map(item => {
          brr.push(item.id)
        })
        const content = {
          employee_ids: brr,
          gender: this.codew,
          isCorpContact: this.publicBusiness,
          status: this.lose,
          // employee_ids:  数组 员工ids
          tagIds: arr, //数组  标签ids
          isNotTag:this.enterpriseLabel==1?1:undefined,//是否选择为所有未打标签，1为选择，''为其他
          start_time:this.sTime, //开始时间
          end_time: this.endTime,//结束时间
          addWay: this.chose //添加渠道
        }
        this.$router.push({
          path: "/contact/index",
          query: {
            content: JSON.stringify(content)
          }
        })
      },
    }
  }
</script>

<style lang="less" scoped>
  /deep/.van-nav-bar__content {
    background: #2b63cd;
    color: #fff;
  }

  /deep/.van-nav-bar .van-icon {
    color: #fff;
  }

  /deep/.van-nav-bar__text {
    color: #fff;
  }

  /deep/.van-nav-bar__title {
    color: #fff;
  }

  .index {
    font-size: 14px;
    height: 100%;
    background: #F1f2f5;

    p {
      margin: 0 !important;
    }

    .qd {
      color: #1890ff !important;
      border-color: #1890ff !important;
      background-color: #f3f9ff !important;
    }

    .content {
      height: 92%;

      img {
        width: 22px;
        height: 22px;
      }

      padding: 12px 16px;

      .item {
        background: #fff;
        padding: 20px 15px;
        height: 100%;
        overflow: auto;
        padding-bottom: 20px;
        position: relative;

        .list-item {
          margin-bottom: 10px;
        }

        .item-title {
          font-weight: bold;
        }

        .item-content {
          width: 100%;
          /* background: red; */
          border: 1px solid #E5E6E7;
          height: 36px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 10px;
          border-radius: 7px;
          margin-top: 10px;
        }

        .item-lable {
          display: inline-block;
          width: auto;
          align-items: center;
          line-height: 36px;
          margin: 10px 3px;
          border-radius: 0;
        }
      }
    }

    .bottom-button {
      display: flex;
      align-items: center;
      position: fixed;
      bottom: 20px;
      justify-content: space-around;
      width: 100%;
    }
  }

  .floatBtn {
    width: 92%;
    position: absolute;
    bottom: 10px;
    margin: 0 auto;
    justify-content: space-around;
    display: flex;
    justify-content: space-between;
  }
</style>