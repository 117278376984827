<template>
  <div class="contact-page">
    <div class="backBox"></div>
    <div v-show="showContact" @click="closeDown" class="show-cont">
      <div class="show-customer">
        <!-- <van-nav-bar title="客户详情" left-text="返回" left-arrow @click-left="onClickLeft" /> -->
        <div class="personal_page">
          <!--      用户信息-->
          <div class="page_flex">
            <div style="display: flex">
              <img
                :src="infoDetail.avatar"
                alt=""
                style="width: 69px; height: 69px; border-radius: 8px"
              />
              <div style="margin-left: 10px">
                <div style="font-size: 16px">
                  {{ infoDetail.name }}
                  <span style="color: #72c871">@微信</span>
                </div>
                <img
                  src="../../assets/male.png"
                  alt=""
                  style="width: 15px; height: 15px; margin-top: 5px"
                  v-if="infoDetail.gender == 1"
                />
                <img
                  src="../../assets/female.png"
                  alt=""
                  style="width: 15px; height: 15px; margin-top: 5px"
                  v-if="infoDetail.gender == 2"
                />
              </div>
            </div>
            <span style="margin-top: 15px">
              <span @click="showContactDetail"><van-icon name="arrow" /></span>
            </span>
          </div>
          <!--   信息   -->
          <div
            style="
              margin-top: 15px;
              padding: 10px;
              background: #f1f2f6;
              border-radius: 4px;
            "
          >
            <div class="page_flex">
              <div style="width: 90%">
                <span class="info_name">添加时间：&nbsp;</span
                >{{ infoDetail.createTime }}
              </div>
            </div>

            <!--<div style="margin-top: 10px" class="page_flex">
                <div style="width: 50%; display: flex">
                  <div class="info_name" style="width: 50px">年龄：</div>
                  <input
                    type="text"
                    class="info_input"
                    placeholder="-"
                    v-model="infoDetail.fieldPivot.age"
                    @blur="setAge"
                    style="width: calc(100% - 50px)"
                  />
                </div>-->
            <!--<div style="width: 50%; display: flex">
                <div class="info_name" style="width: 50px">邮箱：</div>
                <input
                  type="text"
                  class="info_input"
                  placeholder="-"
                  v-model="infoDetail.fieldPivot.email"
                  @blur="setMailbox"
                  style="width: calc(100% - 50px)"
                />
              </div>
            </div>-->
            <div style="margin-top: 10px">
              <span class="info_name">联系电话：</span>
              <span>{{ phoneArr[0] ? phoneArr[0] + "" : "" }}&nbsp;</span>
              <span>{{ phoneArr[1] ? phoneArr[1] + "" : "" }}&nbsp;</span>
              <span>{{ phoneArr[2] ? phoneArr[2] + "" : "" }}&nbsp;</span>
              <div style="margin-left: 44px" v-if="phoneArr[3]">
                <span>{{ phoneArr[3] ? phoneArr[3] + "" : "" }}&nbsp;</span>
              </div>
            </div>
          </div>
          <!--    跟进状态  -->
          <div
            style="padding: 0px 0px 0px 0px; margin-top: 15px; cursor: pointer"
          >
            <div
              @click="$refs.contactFollowStage.show(contactId)"
              class="page_flex"
            >
              <div style="color: #999">跟进状态</div>
              <div>
                {{ textFoll(infoDetail.followUpStatus)
                }}<van-icon name="arrow" />
              </div>
            </div>
          </div>
          <!--      -->

          <!--      -->
        </div>
        <!--    对公业务  -->
        <div style="margin-top: 15px; cursor: pointer" class="duigong">
          <div @click="skipBusiness" class="duiContent">
            <div style="color: #999">对公业务</div>
            <div v-if="isCorpData == 0">个人客户<van-icon name="arrow" /></div>
            <div v-else>企业客户<van-icon name="arrow" /></div>
          </div>
        </div>
        <div
          class="personal_page"
          @click="showFriendRoom"
          style="cursor: pointer; margin-top: 10px"
        >
          <div class="info_name">Ta添加的：</div>
          <div class="page_flex" style="margin-top: 10px; align-items: center">
            <div style="display: flex" class="personal-tip">
              <div
                v-for="(item, index) in infoDetail.employeeName"
                :key="index"
              >
                <img
                  :src="item.avatar"
                  alt=""
                  class="employee-img"
                  v-if="index < 3"
                />
              </div>
              共{{ employees.length }}个员工
            </div>
            <div class="personal-tip">
              共{{ infoDetail.roomName.length }}个群聊<van-icon name="arrow" />
            </div>
          </div>
        </div>
        <div class="personal_page" style="margin-top: 10px">
          <div style="margin-top: 5px">
            <div class="page_flex">
              <div class="row_name">企业标签</div>
              <div
                class="add_btn_style"
                style="
                  width: 69px;
                  height: 33px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
                @click="$refs.corpTag.show(contactId)"
              >
                + 添加
              </div>
            </div>
            <!--      设置标签-->
            <div
              style="display: flex; justify-content: space-between"
              v-for="(item, index) in infoDetail.contactCorpTag"
              :key="index"
            >
              <div style="margin-left: 10px; margin-top: 8px">
                {{ item.groupName }}：
              </div>
              <div style="display: flex; flex-wrap: wrap; margin-top: 2px">
                <div
                  class="tag_name"
                  v-for="(obj, idx) in item.tags"
                  :key="idx"
                >
                  {{ obj.tagName }}
                  <van-icon
                    name="clear"
                    class="close-icon"
                    @click.stop="delEnterpriseTag(obj)"
                  />
                </div>
              </div>
            </div>
          </div>
          <Divider style="margin-top: 10px" />
          <div style="margin-top: 10px">
            <div class="page_flex">
              <div class="row_name">个人标签</div>
              <div
                style="
                  width: 69px;
                  height: 33px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-top: -3px;
                "
                class="add_btn_style"
                @click="$refs.employeeTag.show(contactId)"
              >
                + 添加
              </div>
            </div>
            <!--      设置标签-->
            <div style="display: flex; flex-wrap: wrap; margin-top: 5px">
              <div
                class="tag_name"
                v-for="(item, index) in infoDetail.contactEmployeeTag"
                :key="index"
              >
                {{ item.name }}
                <van-icon
                  name="clear"
                  class="close-icon"
                  @click.stop="delContactTag(item)"
                />
              </div>
            </div>
          </div>
          <Divider style="margin-top: 10px" />
          <div style="margin-top: 10px">
            <div class="page_flex">
              <div class="row_name">朋友圈互动</div>
              <div style="font-size: 14px; color: #999">
                更新于：{{ refreshTime }}
                <span
                  style="font-size: 14px; cursor: pointer"
                  @click="updatePage(true)"
                  ><van-icon name="replay"
                /></span>
              </div>
            </div>
            <div style="margin-top: 15px; display: flex">
              <div class="interaction_num">
                点赞次数
                <Popover
                  v-model:show="giveNum"
                  :actions="giveTips"
                  placement="top-start"
                >
                  <template #reference>
                    <van-icon
                      name="question-o"
                      style="cursor: pointer"
                      @mouseenter="giveNum = true"
                      @mouseout="giveNum = false"
                    />
                  </template>
                </Popover>
                ：<span>{{ infoDetail.moment.likeNumber }}</span>
              </div>
              <div class="interaction_num" style="margin-left: 15px">
                评论次数
                <Popover
                  v-model:show="commentNum"
                  :actions="commentTips"
                  placement="top-start"
                >
                  <template #reference>
                    <van-icon
                      name="question-o"
                      style="cursor: pointer"
                      @mouseenter="commentNum = true"
                      @mouseout="commentNum = false"
                    />
                  </template>
                </Popover>
                ：<span>{{ infoDetail.moment.commentNumber }}</span>
              </div>
            </div>
          </div>
        </div>
        <div style="background: #fff; margin-top: 10px">
          <div
            class="personal_page"
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
          >
            <div class="row_name">动态</div>
            <div class="drop-box" style="font-size: 14px">
              <dropDownCase
                :optionData="optionData"
                @change="selectContactTrack"
                ref="dropDownCase"
              />
            </div>
          </div>
          <div style="position: relative">
            <div class="show-list">
              <van-list
                v-model:loading="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="onLoadData"
              >
                <van-cell v-for="(item, index) in trackList" :key="index">
                  <div style="display: flex">
                    <div>{{ item.week }}</div>
                    <div style="margin-left: 15px">{{ item.day }}</div>
                  </div>
                  <Steps
                    direction="vertical"
                    active-icon="clock"
                    inactive-icon="clock"
                  >
                    <Step v-for="(obj, idx) in item.track" :key="idx">
                      <div style="position: relative">
                        <div
                          class="group_time left-time"
                          style="left: -69px; margin-top: 16px"
                        >
                          {{ obj.createdAt }}
                        </div>
                        <div class="trail-icon">
                          <img
                            src="../../assets/clockImg.png"
                            alt=""
                            v-if="obj.event == 8"
                          />
                          <img src="../../assets/tagsImg.png" alt="" v-else />
                        </div>
                        <div class="group_event" v-if="obj.isNew == 0">
                          {{ obj.content }}
                        </div>
                        <div class="group_event" v-if="obj.isNew == 1">
                          {{ obj.content.title }}
                        </div>
                      </div>
                      <div
                        style="display: flex; justify-content: space-between"
                      >
                        <div
                          class="group_time"
                          v-if="obj.isNew == 1 && obj.event != 99"
                        >
                          <span v-if="obj.todoTime">[{{ obj.todoTime }}]</span>
                          {{ obj.content.content }}
                        </div>
                        <div class="group_time" v-else>
                          <!-- <div class="con">
                            <span>订单日期：</span
                            ><span>{{
                              obj.content.content.buy_order_time
                            }}</span>
                          </div> -->
                          <div class="con">
                            <span>订单状态：</span
                            ><span>{{ obj.content.content.order_status }}</span>
                          </div>
                          <div class="con">
                            <div class="info">产品信息：</div>
                            <More
                              :show="
                                obj.content.content.remark.split('').length >=
                                11
                              "
                              :height="18"
                            >
                              {{ obj.content.content.remark }}
                            </More>
                          </div>
                          <div class="con">
                            <span>价格：</span
                            ><span>{{ obj.content.content.order_price }}</span>
                          </div>
                          <div class="con">
                            <span>商品售后：</span
                            ><span>{{ obj.content.content.after_sales }}</span>
                          </div>
                        </div>
                        <div
                          style="color: #74bcff; cursor: pointer; width: 35px"
                          v-if="obj.event == 6 || obj.event == 7"
                          @click="$refs.contactFollowModify.show(obj)"
                        >
                          修改
                        </div>
                      </div>
                      <div class="group_time" v-if="obj.createName">
                        {{ obj.createName }} 设置
                      </div>
                    </Step>
                  </Steps>
                </van-cell>
              </van-list>
            </div>
          </div>
        </div>
      </div>
      <div class="seek-row">
        <van-button
          type="info"
          block
          @click="seeContactDetail"
          style="margin-bottom: 10px"
          >查看客户</van-button
        >
      </div>
    </div>
    <div
      class="load-box"
      :style="{ height: clientHeight + 'px' }"
      v-if="!showContact"
    >
      <van-loading
        type="spinner"
        color="#1989fa"
        class="load-img"
        v-if="!showContact"
      />
    </div>
    <!--    客户详情-->
    <contactDetail ref="contactDetail" @change="updatePage(true)" />
    <!--    客户评分-->
    <contactScore ref="contactScore" />
    <!--    客户积分-->
    <contactIntegral ref="contactIntegral" />
    <!--    选择跟进状态-->
    <contactFollowStage ref="contactFollowStage" @change="updatePage" />
    <!--    好友和群聊-->
    <friendRoom ref="friendRoom" />
    <!--    添加企业标签-->
    <corpTag ref="corpTag" @change="updatePage" />
    <!--    个人标签-->
    <employeeTag ref="employeeTag" @change="updatePage" />
    <!--    添加待办-->
    <contactTodo ref="contactTodo" @change="updatePage" />
    <!--    添加跟进-->
    <contactFollow ref="contactFollow" @change="updatePage" />
    <!--    修改跟进-->
    <contactFollowModify ref="contactFollowModify" @change="updatePage" />
    <!--    转接客户-->
    <contactTransfer ref="contactTransfer" />
    <!--    模板拉群-->
    <roomTemplatePull ref="roomTemplatePull" />
  </div>
</template>
  <script>
import { ContactApi } from "@/api/contact";
// eslint-disable-next-line no-unused-vars
import { ContactTrackApi } from "@/api/contactTrack";
import { Divider, Step, Steps, Tabbar, TabbarItem, Toast, Popover } from "vant";
import dropDownCase from "@/components/dropDownCase/dropDownCase";
import contactDetail from "./components/contactDetail";
import contactScore from "./components/contactScore";
import contactIntegral from "./components/contactIntegral";
import contactFollowStage from "./components/contactFollowStage";
import friendRoom from "./components/friendRoom";
import corpTag from "./components/corpTag";
import employeeTag from "./components/employeeTag";
import contactTodo from "./components/contactTodo";
import contactFollow from "./components/contactFollow";
import contactFollowModify from "./components/contactFollowModify";
import contactTransfer from "./components/contactTransfer";
import roomTemplatePull from "./components/roomTemplatePull";
// eslint-disable-next-line no-unused-vars
import { openUserProfile } from "@/utils/wxCodeAuth";
export default {
  components: {
    contactDetail,
    roomTemplatePull,
    contactTransfer,
    Divider,
    Step,
    Steps,
    Popover,
    contactScore,
    contactIntegral,
    contactFollowStage,
    friendRoom,
    corpTag,
    employeeTag,
    contactTodo,
    contactFollow,
    Tabbar,
    TabbarItem,
    dropDownCase,
    contactFollowModify,
  },
  data() {
    return {
      spanIndex: 0,
      showMore: [false, false, false, false],
      phoneArr: [],
      infoDetail: {
        fieldPivot: {},
        roomName: [],
        employeeName: [],
        moment: {},
      },
      isCorpData: "",
      trackList: [],
      employees: [],
      clientEvent: 99,
      showContact: false,
      finished: false,
      loading: false,
      page: 1,
      clientHeight: "",
      optionData: [
        {
          id: 99,
          name: "产品信息",
        },
        {
          id: 0,
          name: "全部",
        },
        {
          id: 1,
          name: "客户动态",
        },
        {
          id: 3,
          name: "修改信息",
        },
        {
          id: 6,
          name: "跟进记录",
        },
        {
          id: 8,
          name: "待办事项",
        },
        {
          id: 7,
          name: "模板记录",
        },
      ],
      //  更新时间
      refreshTime: "",
      //  点赞次数
      giveNum: false,
      giveTips: [
        {
          text: "企业微信朋友圈点赞总次数",
        },
      ],
      //  评论次数
      commentNum: false,
      commentTips: [
        {
          text: "企业微信朋友圈评论总次数",
        },
      ],
      tabActive: 0,
    };
  },
  async created() {
    this.contactId = this.$route.query.contactId;
    this.wxExternalUserid = this.$route.query.wxExternalUserid;
    this.showContact = false;
    this.trackList = [];
    this.clientHeight = document.documentElement.clientHeight;
    // const entry = await getContext()
    // const agentId = this.$route.query.agentId
    // if (entry === 'group_chat_tools') {
    //   this.$router.push({ path: '/room', query: { agentId } })
    //   return
    // }
    // if (entry !== 'single_chat_tools') {
    //   this.$toast({ position: 'top', message: '请从单聊会话的工具栏进入' })
    //   return
    // }
    // await this.getContactInfo()
    // if (!this.contactId) {
    //   this.$toast({ position: 'top', message: '获取客户信息失败，请重新进入应用' })
    //   return
    // }

    // this.showContact = true
    // 基本信息
    this.getInfo();
    // 互动轨迹
    setTimeout(() => {
      this.$refs.dropDownCase.setOption(99);
      this.onLoadData();
    }, 1000);
    //  客户详情信息
    this.getDetail();
  },
  methods: {
    handleShowMore(idx) {
      console.log("idx", idx);
      this.spanIndex = idx;
      console.log("gdg,", !this.showMore[idx]);
      this.$nextTick(() => {
        this.showMore[idx] = false;
      });
    },
    handleShowLess(idx) {
      console.log("idx", idx);
      this.spanIndex = idx;
      console.log("hgh");
      this.$nextTick(() => {
        this.showMore[idx] = true;
      });
    },
    skipBusiness() {
      this.$router.push({
        path: "/contact/selectBinding",
        query: {
          contactId: this.contactId,
        },
      });
    },
    async seeContactDetail() {
      await openUserProfile(2, this.wxExternalUserid);
    },
    onClickLeft() {
      this.$router.back();
    },
    // 获取当前时间
    getCurrentTime() {
      const date = new Date();
      const year = date.getFullYear();
      const month = new Date().getMonth() + 1;
      const day =
        new Date().getDate() < 10
          ? "0" + new Date().getDate()
          : new Date().getDate();
      const hh =
        new Date().getHours() < 10
          ? "0" + new Date().getHours()
          : new Date().getHours();
      const mm =
        new Date().getMinutes() < 10
          ? "0" + new Date().getMinutes()
          : new Date().getMinutes();
      this.refreshTime = year + "-" + month + "-" + day + " " + hh + ":" + mm;
    },
    // 客户画像
    getDetail() {
      ContactApi.getContactPortrait({
        contactId: this.contactId,
      }).then((res) => {
        this.detail = res.data;
      });
    },
    // 修改客户信息
    modifyContact() {
      ContactApi.updateContactPortrait({
        contactId: this.contactId,
        userPortrait: JSON.stringify(this.detail),
      }).then((res) => {
        Toast("修改成功");
        this.updatePage(true);
      });
    },
    // 设置电话
    setPhone() {
      if (!/^1[3456789]\d{9}$/.test(this.infoDetail.fieldPivot.phone)) {
        Toast("手机号格式错误");
        return false;
      }
      this.detail.map((item, index) => {
        if (item.name == "手机号") {
          item.value = this.infoDetail.fieldPivot.phone;
        }
        return item;
      });
      this.modifyContact();
    },
    // 设置年龄
    setAge() {
      if (!/^[0-9]*$/.test(this.infoDetail.fieldPivot.age)) {
        Toast("年龄格式错误");
        return false;
      }
      this.detail.map((item, index) => {
        if (item.name == "年龄") {
          item.value = this.infoDetail.fieldPivot.age;
        }
        return item;
      });
      this.modifyContact();
    },
    // 设置邮箱
    setMailbox() {
      if (this.infoDetail.fieldPivot.email == "") {
        Toast("邮箱格式错误");
        return false;
      }
      this.detail.map((item, index) => {
        if (item.name == "邮箱") {
          item.value = this.infoDetail.fieldPivot.email;
        }
        return item;
      });
      this.modifyContact();
    },
    // 关闭下拉
    closeDown() {
      this.$refs.dropDownCase.closeOption();
    },
    // 更新页面
    updatePage(e) {
      if (e) {
        this.getInfo();
        this.trackList = [];
        this.page = 1;
        this.onLoadData();
      }
    },
    // 处理互动轨迹数据
    handTrackList(data) {
      if (data.length != 0) {
        data.forEach((item, index) => {
          item.track.forEach((obj, idx) => {
            if (obj.isNew == 1) {
              obj.content = JSON.parse(obj.content);
            }
          });
          this.trackList.push(item);
        });
        this.loading = false;
        this.page++;
      } else {
        this.finished = true;
      }
    },
    // 选择客户动态
    selectContactTrack(e) {
      this.clientEvent = e;
      this.trackList = [];
      this.page = 1;
      this.onLoadData();
    },
    // 下拉加载
    onLoadData() {
      this.loading = true;
      this.finished = false;
      this.getTrackData();
    },
    // 互动轨迹
    getTrackData() {
      ContactTrackApi.index({
        contactId: this.contactId,
        event: this.clientEvent,
        page: this.page,
        // perPage: 10
      }).then((res) => {
        this.handTrackList(res.data.list);
      });
    },
    // 删除企业标签
    delEnterpriseTag(obj) {
      ContactApi.deleteContactTagApi({
        contactId: this.contactId,
        tagId: obj.tagId,
      }).then((res) => {
        Toast("删除成功");
        this.updatePage(true);
      });
    },
    // 删除个人标签
    delContactTag(item) {
      ContactApi.employeeTagDeleteApi({
        contactId: this.contactId,
        tagId: item.id,
      }).then((res) => {
        Toast("删除成功");
        this.updatePage(true);
      });
    },
    // 显示用户详情
    showContactDetail() {
      const contactDetailInfo = {
        contactId: this.contactId,
        avatar: this.infoDetail.avatar,
        name: this.infoDetail.name,
      };
      this.$refs.contactDetail.show(contactDetailInfo);
    },
    // 显示好友群聊
    showFriendRoom() {
      const roomData = {
        roomName: this.infoDetail.roomName,
        employeeName: this.employees,
      };
      this.$refs.friendRoom.show(roomData);
    },
    // 客户详情基本信息
    getInfo() {
      // 获取当前时间
      this.getCurrentTime();
      const params = {
        contactId: this.contactId,
      };
      ContactApi.show(params).then((res) => {
        this.infoDetail = res.data;
        this.phoneArr = this.infoDetail.phone.split(",");
        this.isCorpData = this.infoDetail.isCorpContact;
        console.log("电话", this.phoneArr);
        this.employees = this.infoDetail.employees;
        this.wxExternalUserid = this.infoDetail.wxExternalUserid;
        setTimeout(() => {
          this.showContact = true;
        }, 500);
      });
    },
    changeBar(type) {
      if (type == 1) {
        this.$refs.contactTodo.show(this.contactId);
      }
      if (type == 2) {
        this.$refs.contactFollow.show(this.contactId);
      }
      if (type == 3) {
        this.$refs.contactTransfer.show(this.contactId);
      }
      if (type == 4) {
        const userInfo = {
          contactId: this.contactId,
          avatar: this.infoDetail.avatar,
          name: this.infoDetail.name,
          wxExternalUserid: this.infoDetail.wxExternalUserid,
        };
        this.$refs.roomTemplatePull.show(userInfo);
      }
    },
    textFoll(e) {
      switch (e) {
        case 1:
          return "新客户";
          break;
        case 2:
          return "初步沟通";
          break;
        case 3:
          return "意向客户";
          break;
        case 4:
          return "付款客户";
          break;
        case 5:
          return "无意向客户";
          break;
        default:
          return "暂无状态";
      }
    },
  },
};
</script>
  <style  lang="less" scoped>
/deep/ .van-tabbar-item--active {
  color: #3975c6 !important;
}
/deep/ .van-tab {
  font-size: 16px;
  color: #fff;
}
/deep/ .van-tabs {
  margin-top: -4px;
}
/deep/ .van-tabs__nav--line {
  padding-bottom: 0px;
}
/deep/ .van-tabs__line {
  bottom: 1px;
}
/deep/ .van-nav-bar__left {
  font-size: 16px;
}

/deep/ .van-nav-bar__content {
  background: #2b63cd;
  color: #fff;
}

/deep/ .van-nav-bar .van-icon {
  color: #fff;
}

/deep/ .van-nav-bar__text {
  color: #fff;
}

/deep/ .van-nav-bar__title {
  color: #fff;
}

.duigong {
  padding: 10px 0;
  background: #ffffff;
  font-size: 13px;
  color: #4a4a4a;
}

.duiContent {
  display: flex;
  width: 90%;
  margin: 0 auto;
  justify-content: space-between;
}

.van-nav-bar__right {
  div {
    color: #fff !important;
  }
}

.contact-page {
  font-size: 16px;
  .backBox {
    width: 100%;
    height: 124px;
    background: linear-gradient(
      180deg,
      #3974c7 0%,
      #e2eeff 74%,
      rgba(255, 255, 255, 0) 100%
    );
    position: absolute;
    top: 40px;
  }
}

.show-cont {
  padding: 0 12px;
  position: relative;
  z-index: 19;
}

.show-customer {
  height: calc(100vh - 100px);
  overflow-y: scroll;
}

.seek-row {
  background: #fff;
  padding: 2px 30px;
}

.personal-tip {
  font-size: 16px;
  color: #666;
}

.employee-img {
  width: 40px;
  height: 40px;
  margin-top: 5px;
  border-radius: 50%;
}

.drop-box {
  //position: absolute;
  //top: 10px;
  //right: 15px;
  z-index: 200;
}

.contact-page {
  background: #f3f4f8;
}

.load-box {
  width: 100%;
  position: relative;
}

.load-img {
  position: absolute;
  top: 40%;
  left: 50%;
}

.page_flex {
  display: flex;
  justify-content: space-between;
}

.personal_page {
  font-size: 14px;
  padding: 20px;
  background: #fff;
  color: #666;
  border-radius: 6px;
  margin-top: 14px;
}

.info_input {
  border: 0;
  background: #fff;
}

.info_name {
  color: #999;
}

.row_name {
  color: #333;
  font-size: 16px;
  height: 30px;
  line-height: 30px;
}

.add_btn_style {
  height: 40px;
  width: 110px;
  font-size: 16px;
  color: #999;
  text-align: center;
  line-height: 43px;
  cursor: pointer;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  box-sizing: content-box;
}

.add_btn_style:hover {
  border: 1px dashed #e8e8e8;
}

.interaction_num {
  span {
    font-size: 16px;
    color: #2791ff;
  }
}

.show-list {
}

.show-list /deep/ .van-steps__items {
  padding-left: 50px;
}

.left-time {
  position: absolute;
  top: -15px;
  left: -125px;
  z-index: 100;
}

//步骤条
.trail-icon {
  position: absolute;
  top: 0;
  left: -30px;
  z-index: 100;
  background: #fff;
  width: 20px;
  height: 20px;
  border: 1px solid #42a4ff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 10px;
    height: 10px;
  }
}

.show-list /deep/ .van-step__icon--active {
  //color: #42A4FF;
  color: #fff;
}

.show-list /deep/ .van-step__circle-container {
  font-size: 16px;
  //color: #42A4FF;
  color: #fff;
}

.show-list /deep/ .van-step__title {
  color: #333;
  margin-left: 5px;
}

.group_event {
  font-weight: bold;
}

.group_time {
  margin-top: 15px;
  color: #747474;
}

.option_row {
  width: 270px;
  height: 50px;
  border: 1px solid #cccccc;
  position: relative;
}

.option_row select {
  position: absolute;
  top: 0;
  /*清除select的边框样式*/
  border: none;
  /*清除select聚焦时候的边框颜色*/
  outline: none;
  /*将select的宽高等于div的宽高*/
  width: 100%;
  height: 50px;
}

.option_row option {
  height: 10px;
}

.show_user_data {
  width: 50%;
  margin-top: 5px;
}

/deep/ .van-field__label {
  color: #919191;
  width: 90px;
}

.picture-wrapper,
.check-wrapper {
  align-items: center;
  margin-top: 16px;
  margin-left: 25px;
  display: flex;
  color: #919191;

  .title {
    width: 90px;
    font-size: 16px;
    display: inline-block;
  }

  .picture {
    width: 128px;
    height: 128px;
    margin-left: 20px;
  }

  .text {
    color: #cac8c8;
    font-size: 16px;
  }

  .check-group {
    .check-item {
      margin: 10px 0;
    }
  }
}

.tag_name {
  border: 1px solid #cce5ff;
  background: #f6faff;
  padding: 8px 17px;
  border-radius: 8px;
  margin-top: 10px;
  margin-left: 25px;
  font-size: 16px;
  height: 30px;
  line-height: 32px;
  position: relative;
  cursor: pointer;
  letter-spacing: 1px;
  color: #5c5c5c;
  box-sizing: content-box;
  display: flex;
  align-items: center;
}

.close-icon {
  display: none;
  font-size: 16px;
  margin-left: 5px;
  position: absolute;
  top: -13px;
  right: -15px;
  z-index: 100;
  color: #7e99b0;
}

.tag_name:hover .close-icon {
  display: block;
}
.con {
  width: 300px;
  display: flex;
  line-height: 20px;
}
.info {
  width: 24%;
}
</style>