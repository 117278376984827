import request from "../plugins/axios";

//创建
export function createOrder(params) {
    return request({
        url: '/WorkSheet/store',
        method: 'post',
        data:params
    })
}
//列表
export function orderList(params) {
    return request({
        url: '/WorkSheet/index',
        method: 'get',
        params:params
    })
}
// 抢单
export function robOrder(params) {
    return request({
        url: '/WorkSheet/receive',
        method: 'post',
        data:params
    })
}
//审批工单
export function verifyOrder(params) {
    return request({
        url: '/WorkSheet/verify',
        method: 'post',
        data:params
    })
}
//工单统计
export function orderStatistics(params) {
    return request({
        url: '/WorkSheet/statistics',
        method: 'get',
        params:params
    })
}
//工单详情
export function orderDetail(params) {
    return request({
        url: '/WorkSheet/show',
        method: 'get',
        params:params
    })
}