<template>
    <div class="createPage">
        <div class="mapBox">
            <baidu-map class='bm-view' :center="center" :zoom="zoom" @ready="handler" :dragging="dragging"
                ak="hvv31Hzt069G4NGGeDbpobdTOoDQhI3r">
                <bm-circle :center="center" :radius="300" strokeColor="#EFA59B" :strokeOpacity="0.2" :fillOpacity="0.6"
                    fillColor="#EFA59B"></bm-circle>
                <bm-marker :position="center" :dragging="false" animation="BMAP_ANIMATION_BOUNCE">
                    <!-- <bm-info-window :show="true" >{{params.addr}}</bm-info-window> -->
                    <!-- <bm-info-window :position="center" :show="true">
                        <p v-text="initialAdd" style="font-size: 12px;"></p>
                    </bm-info-window> -->
                </bm-marker>
            </baidu-map>
        </div>
        <div class="infoBox">
            <div class="label">
                <div class="labelNeme">打卡地址 <span class="must">*</span></div>
                <van-field v-model="params.addr" readonly class="labelInput" placeholder="地址详情"
                    @click="addressShow=true" />
                <div class="labelSelect">
                    <img src="../../assets/images/clockIn/select.png" alt="">
                </div>
            </div>
            <div class="label">
                <div class="labelNeme">客户名称 <span class="must">*</span></div>
                <van-field v-model="params.contact_name" class="labelInput" placeholder="请输入客户名称" />
            </div>
            <div class="label">
                <div class="labelNeme">联系方式 </div>
                <van-field v-model="params.contact_phone" class="labelInput" placeholder="请输入客户手机号（选填）" />
            </div>
            <div class="label">
                <div class="labelNeme">备 注 </div>
                <van-field v-model="params.remark" class="labelInput" placeholder="备注" />
            </div>
            <div class="label labelleft">
                <div class="labelNeme">拍照记录 <span class="must">*</span></div>
                <van-uploader :after-read="afterRead" v-model="fileList" :max-count="1"
                    class="cameraBox"></van-uploader>
            </div>
            <div class="clockBox">
                <div class="circle" @click="clockFun">
                    <div class="info">
                        <div class="clockTime">{{ currentTime }}</div>
                        <div class="clockName">打卡</div>
                    </div>
                </div>
            </div>
        </div>
        <van-popup v-model="addressShow" round position="bottom" :style="{ height: '50%' }">
            <van-picker show-toolbar :columns="columns" @confirm="onConfirm" @cancel="onCancel" :default-index="pickerIndex" />
        </van-popup>
    </div>
</template>
<script>
    import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
    import BmMarker from 'vue-baidu-map/components/overlays/Marker.vue'
    import bmInfoWindow from "vue-baidu-map/components/overlays/InfoWindow.vue"
    import BmLocalSearch from 'vue-baidu-map/components/search/LocalSearch.vue'
    import BmCircle from 'vue-baidu-map/components/overlays/Circle.vue'
    import BmGeolocation from 'vue-baidu-map/components/controls/Geolocation.vue'
    import { Dialog } from "vant";
    import { getUserLocation } from "@/utils/wxCodeAuth";
    import { createClock, upload } from "@/api/externalClock";
    export default {
        components: {
            BaiduMap,
            BmMarker,
            bmInfoWindow,
            BmLocalSearch,
            BmCircle,
            BmGeolocation
        },
        data() {
            return {
                //BMap类
                BMap: null,
                //地图对象
                map: null,
                center: { lng: 116.404, lat: 39.915 },
                zoom: 16,
                // 地图坐标解析器
                gc: '',
                address: '北京市东城区中华路甲10号',
                location: '深圳',
                dragging: true,
                addressShow: false,
                text: '',
                currentTime: '',
                timer: null,
                fileList: [],
                params: {
                    addr: undefined,
                    lat: undefined,
                    lng: undefined,
                    clock_img: undefined,
                    contact_name: undefined,
                    contact_phone: undefined,
                    remark: undefined,
                },
                columns: [],
                initialAdd:'',
                initialAddress:'',
                pickerIndex:0
            }
        },
        created() {

        },
        mounted() {
            this.updateTime(); // 首次加载时立即更新时间
            this.timer = setInterval(this.updateTime, 1000);// 每秒更新一次时间
        },
        beforeDestroy() {
            clearInterval(this.timer);
        },
        methods: {
            updateTime() {
                const now = new Date();
                const hours = String(now.getHours()).padStart(2, '0');
                const minutes = String(now.getMinutes()).padStart(2, '0');
                const seconds = String(now.getSeconds()).padStart(2, '0');
                this.currentTime = `${hours}:${minutes}:${seconds}`;
            },
            async handler({ BMap, map }) {
                //获取用户位置
                const loctionInfo = await getUserLocation();
                // const loctionInfo = { longitude: 114.0303726196289, latitude: 22.66407012939453 }
                // const loctionInfo = { longitude: 113.0750102123021, latitude: 28.169147464291367}
                this.center = await this.qqMapTransBdMap(loctionInfo.longitude, loctionInfo.latitude)
                this.params.lng = loctionInfo.longitude;
                this.params.lat = loctionInfo.latitude;
                this.zoom = 16
                //创建地址解析器实例
                this.gc = new BMap.Geocoder();
                this.localSearch = new BMap.LocalSearch(map, {
                    renderOptions: { map: map },
                });
                this.map = map
                var that = this;
                //解析经纬度地址
                var myGeo = new BMap.Geocoder();
                var pt = new BMap.Point(this.center.lng, this.center.lat);
                myGeo.getLocation(pt, function (result) {
                    if (result) {
                        let newAddress=result.surroundingPois!=0?result.surroundingPois[0].title:''
                        that.params.addr = result.address+newAddress
                        that.initialAdd=result.address
                        that.initialAddress=result.address
                        that.columns=[]
                        result.surroundingPois.forEach((item,index) => {
                            that.columns.push(item.title)
                        })
                    }
                });

            },
            /**
             * @description 高德转百度
             * @description  GCJ-02 转 BD-09
             * @param{*}list [lng,lat]
             */
            qqMapTransBdMap(lng, lat) {
                let x_pi = 3.14159265358979324 * 3000.0 / 180.0;
                let x = lng;
                let y = lat;
                let z = Math.sqrt(x * x + y * y) + 0.00002 * Math.sin(y * x_pi);
                let theta = Math.atan2(y, x) + 0.000003 * Math.cos(x * x_pi);
                let lngs = z * Math.cos(theta) + 0.0065;
                let lats = z * Math.sin(theta) + 0.006;
                return { lng: lngs, lat: lats };
            },
            // 上传图片前压缩图片
            afterRead(file) {
                file.status = "uploading";
                const reader = new FileReader();
                reader.onload = (event) => {
                    const img = new Image();
                    img.onload = () => {
                        const maxWidth = 800;
                        const maxHeight = 800;
                        let width = img.width;
                        let height = img.height;
                        if (width > maxWidth || height > maxHeight) {
                            const ratio = Math.min(maxWidth / width, maxHeight / height);
                            width *= ratio;
                            height *= ratio;
                        }
                        const canvas = document.createElement("canvas");
                        canvas.width = width;
                        canvas.height = height;
                        const ctx = canvas.getContext("2d");
                        ctx.drawImage(img, 0, 0, width, height);
                        canvas.toBlob((blob) => {
                            const compressedFile = new File([blob], file.file.name, { type: file.file.type });
                            this.uploadCompressedFile(compressedFile);
                        }, file.file.type);
                    };

                    img.src = event.target.result;
                };
                reader.readAsDataURL(file.file);
            },
            // 上传图片
            uploadCompressedFile(file) {
                const formData = new FormData();
                formData.append("file", file);
                // 调用上传接口
                upload(formData).then((res) => {
                    file.status = "done";
                    this.params.clock_img = res.data.fullPath
                    let filedata = {
                        url: res.data.fullPath,
                        path: res.data.path
                    }
                    this.$set(this.fileList, 0, filedata);
                    // 处理上传成功的逻辑
                    // console.log("上传成功:", res);
                }).catch((error) => {
                    // 处理上传失败的逻辑
                    console.error("上传失败:", error);
                });
            },
            // 提交打卡
            async clockFun() {
                if (this.params.contact_name == undefined || this.params.contact_name == '') {
                    this.$message.warning("请输入客户名称");
                    return
                }
                if (this.params.clock_img == undefined || this.params.clock_img == '') {
                    this.$message.warning("请上传图片");
                    return
                }
                const phoneRegex = /^1[3456789]\d{9}$/;
                // 验证手机号是否符合规则
                if (this.params.contact_phone != undefined && this.params.contact_phone != '') {
                    if (!phoneRegex.test(this.params.contact_phone)) {
                        this.$message.warning("请输入正确的手机号格式");
                        return
                    }
                }
                const { code, data } = await createClock(this.params)
                if (code == 200) {
                    Dialog.confirm({
                        message: "外拓打卡提交成功！",
                        confirmButtonColor: "#1890ff",
                        cancelButtonText: '返回首页',
                        confirmButtonText: '继续打卡'
                    }).then(() => {

                    }).catch(() => {
                            this.$router.back()
                        });
                }
            },
            // 选址确认
            onConfirm(value, index) {
                this.pickerIndex=index
                this.params.addr =  this.initialAddress + value
                this.addressShow = false
            },
            // 选址取消
            onCancel() {
                this.addressShow = false
            },
        }
    }
</script>
<style lang="less" scoped>
    /deep/ .van-cell {
        padding: 0px 6px;
    }

    /deep/ .van-field__control {
        white-space: nowrap;
        /* 禁止换行 */
        overflow: hidden;
        /* 超出部分隐藏 */
        text-overflow: ellipsis;
        /* 超出部分显示省略号 */
    }

    .createPage {
        width: 100%;
        height: 100%;
        background: #FFFFFF;

        .mapBox {
            width: 100%;
            height: 304px;

            .bm-view {
                width: 100%;
                height: 100%;
            }
        }

        .infoBox {
            background: #FFFFFF;
            padding: 10px 20px;
            width: 100%;
            /* height: calc(100% - 304px); */

            .label {
                display: inline-flex;
                justify-content: space-between;
                // align-items: center;
                width: 100%;
                margin-bottom: 5px;

                .labelNeme {
                    width: 25%;
                    height: 31px;
                    font-size: 15px;
                    font-weight: 400;
                    color: #333333;
                    line-height: 31px;
                    display: flex;
                    align-items: center;

                    .must {
                        margin-left: 4px;
                        width: 8px;
                        height: 21px;
                        font-size: 15px;
                        font-weight: 400;
                        color: #C02C00;
                        line-height: 24px;
                    }
                }

                .labelInput {
                    margin-left: 6px;
                    width: 75%;
                    height: 31px;
                    background: #F2F3F7;
                    border-radius: 2px;
                    border: 0px solid #BBBBBB;
                    line-height: 31px;

                }

                .labelSelect {
                    height: 31px;
                    background: #F2F3F7;
                    line-height: 27px;

                    img {
                        width: 10px;
                        height: 15px;
                        margin-right: 3px;
                    }
                }

                .cameraBox {

                    margin-left: 6px;
                    margin-top: 5px;
                }
            }

            .labelleft {
                display: flex;
                justify-content: flex-start;
            }

            .clockBox {
                margin-top: 24px;
                width: 100%;
                display: flex;
                justify-content: center;

                .circle {
                    width: 128px;
                    height: 128px;
                    position: relative;
                    background: #3975C6;
                    border: 8px solid #CDE3FF;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .info {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        text-align: center;
                        z-index: 2;

                        .clockTime {
                            font-size: 19px;
                            font-weight: bold;
                            color: #FFFFFF;
                        }

                        .clockName {
                            font-size: 16px;
                            font-weight: 500;
                            color: #FFFFFF;
                        }
                    }

                    &::before {
                        content: "";
                        position: absolute;
                        top: -8px;
                        left: -8px;
                        width: calc(100% + 16px);
                        height: calc(100% + 16px);
                        border: 8px solid #CDE3FF;
                        border-radius: 50%;
                        animation: wave 2s linear infinite;
                        z-index: 1;
                    }
                }
            }

            @keyframes wave {
                0% {
                    box-shadow: 0 0 0 0 #CDE3FF;
                }

                50% {
                    box-shadow: 0 0 0 8px #CDE3FF;
                }

                100% {
                    box-shadow: 0 0 0 0 #CDE3FF;
                }
            }
        }

    }
</style>