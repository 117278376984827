<template>
  <div class="create">
    <div class="info">基础信息</div>
    <van-form @submit="onSubmit" class="mark">
      <van-field
        v-model="form.name"
        label="工单名称"
        placeholder="请输入工单名称"
        :rules="[{ required: true }]"
      />
      <van-field
        v-model="form.addr"
        label="经营地址"
        placeholder="请选择地址"
        right-icon="arrow"
        readonly
        @click="regionBtn"
        :rules="[{ required: true }]"
      />
      <van-field
        v-model="form.contact_name"
        label="客户名称"
        placeholder="请输入客户名称"
        :rules="[{ required: true }]"
      />
      <van-field
        v-model="form.contact_phone"
        label="联系电话"
        placeholder="请输入联系电话"
        type="digit"
        :rules="[{ required: true }]"
      />
      <van-field name="uploader" label="图片">
        <template #input>
          <van-uploader
            multiple
            v-model="fileList"
            @delete="imgChange($event, item)"
            :after-read="afterRead"
            :before-read="beforeRead"
            result-type="file"
            :max-count="1"
          >
          </van-uploader>
        </template>
      </van-field>
      <van-field
        v-model="form.remark"
        label="备注"
        placeholder="备注"
      />
      <div class="filed">
        <div class="top_filed">分配成员(选填)</div>
        <div class="input">
          <selectEmployee @change="getCount" ref="groups" />
        </div>
      </div>
      <div class="btnBox">
        <div class="cancel" @click="$router.push('/workOrder/index')">取消</div>
        <van-button class="submit" native-type="submit">确定</van-button>
      </div>
    </van-form>

    <van-dialog
      v-model="showPopup"
      @confirm="$router.push('/workOrder/myOrder')"
      @cancel="$router.push('/workOrder/index')"
      confirmButtonText="我的工单"
      cancelButtonText="工单首页"
      show-cancel-button
      confirmButtonColor="#3974C6"
    >
    <div @click="showPopup=false" style="text-align:right;width:95%;padding-top:6px"><img class="closeBtn" src="../../assets/workOrder/close.png" alt=""></div>  
      <div class="popupContent">工单发起成功！</div>
    </van-dialog>
    <!-- 地图 -->
    <van-popup v-model="mapShow" position="bottom" :style="{ height: '100%' }">
      <mapPage ref="popChild" @mapSave="mapSave"></mapPage>
    </van-popup>
  </div>
</template>
<script>
import selectEmployee from "@/components/select/employee/select";
import { ContactApi } from "@/api/contact";
import mapPage from "./mapPage";
import { createOrder } from "@/api/workOrder";
export default {
  components: { selectEmployee, mapPage },
  // 是否限制单选标识
  provide: {
    tranferMessage: 1,
  },
  data() {
    return {
      //BMap类
      BMap: null,
      showPopup: false,
      mapShow: false,
      fileList: [],
      //   employeeIds: [],
      form: {
        name: "",
        addr: "",
        lng: "",
        lat: "",
        contact_name: "",
        contact_phone: "",
        image: "",
        remark: "",
        employee_id: "",
      },
    };
  },
  methods: {
    handleSubmit() {},
    imgChange(data, item) {
      item.value = "";
    },
    // 获取员工Id
    getCount() {
      var id = this.$refs.groups.get().map((v) => {
        return v.id;
      });
      this.form.employee_id = id[0];
      console.log("employeeIds", this.form.employee_id);
    },
    beforeRead(data) {
      if (data instanceof Array) {
        this.$toast({ position: "top", message: "最多选择一张图片" });
        return false;
      } else {
        return true;
      }
    },
    afterRead(data) {
      const params = new FormData();
      params.append("file", data.file);
      ContactApi.upload(params).then((res) => {
        this.fileList = [{ url: res.data.fullPath }];
        this.form.image = res.data.fullPath;
      });
    },
    onSubmit() {
      createOrder(this.form).then((res) => {
        if (res.code == 200) {
          this.showPopup = true;
        }
      });
    },
    //选取区域
    regionBtn(item) {
      // this.currentMapid = item.contactFieldId
      this.mapShow = true;
      //   this.$nextTick(() => {
      //     this.$refs.popChild.keyword = item.value; //修改子组件data
      //     this.$refs.popChild.address = item.value; //修改子组件data
      //   });
    },
    //地图选址确认事件
    mapSave(row) {
      console.log("df", row);
      this.form.addr = row;
      var that = this;
      this.getLatLng(row).then((res) => {
        that.form.lng = res.lng;
        that.form.lat = res.lat;
      });
      this.mapShow = false;
    },
    // 地址转经纬度
    getLatLng(address) {
      return new Promise((resolve, reject) => {
        // const map = new BMap.Map("mapContainer");
        const geocoder = new BMap.Geocoder();
        geocoder.getPoint(
          address,
          (point) => {
            if (point) {
              resolve({
                lng: point.lng,
                lat: point.lat,
              });
            } else {
              reject("无法获取地址的经纬度");
            }
          },
          "中国"
        );
      });
    },
    //地图取消
    closeMap() {
      this.mapShow = false;
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .van-field__label {
  min-width: 50%;
  height: 80px;
  color: #515151;
}
/deep/ .mark .van-cell {
  display: block;
  border-radius: 5px;
  padding: 0.26667rem 0.3rem;
}
/deep/ .van-cell__title {
  height: 40px !important;
}
/deep/ .myfiled .van-field__value {
  height: 163px !important;
  background: #f2f3f7 !important;
  border-radius: 5px;
}
.info {
  padding-left: 2%;
  height: 43px;
  font-size: 14px;
  font-weight: 500;
  color: #515151;
  line-height: 43px;
  background-color: #f2f3f7;
}
.filed {
  width: 100%;
  min-height: 83px;
  background-color: #fff;
  .top_filed {
    width: 94%;
    margin: 0 auto;
    padding-top: 10px;
    margin-bottom: 12px;
    height: 20px;
    font-size: 14px;
    color: #515151;
    line-height: 20px;
  }
  .input {
    display: flex;
    width: 94%;
    margin: 0 auto;
  }
}
.btnBox {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 12px 10px 12px;

  .cancel {
    width: 30%;
    padding: 12px 0;
    text-align: center;
    background: #f1f2f6;
    border-radius: 5px;
    border: 1px solid #d7d7d9;
    font-size: 17px;
    font-weight: 500;
    color: #595a5e;
  }

  .submit {
    width: 65%;
    text-align: center;
    padding: 24px 0;
    background: #3974c6;
    border-radius: 5px;
    font-size: 17px;
    font-weight: 500;
    color: #ffffff;
  }
}
.popupContent {
  font-size: 17px;
  font-weight: 500;
  color: #000000;
  text-align: center;
  margin: 10px 0 20px 0;
}
.closeBtn{
  width: 13px;
  height: 13px;
}
</style>