<template>
  <div>
    <Popup v-model:show="userDetail" position="bottom" closeable :close-on-click-overlay="false"
      :style="{ height: '600px' }">
      <div style="font-size: 14px;font-weight: bold;text-align: center;padding-top: 15px;">客户资料详情</div>
      <Divider />
      <div class="user_flex user_page" style="padding-bottom: 20px;">
        <div style="display: flex;">
          <img :src="avatar" alt="" style="width: 50px;height: 50px;">
          <div style="margin-left: 10px;font-size: 14px">
            <div style="font-size: 16px;font-weight: bold;">{{ userName }}</div>
            <div style="color: #72C871;margin-top: 5px;">@微信</div>
          </div>
        </div>
      </div>
      <van-cell-group class="data-group">
        <div v-for="(item, index) in detail" :key="index">
          <div v-if="item.typeText == '图片'" class="picture-wrapper">
            <span class="title">{{ item.name }}</span>
            <van-uploader multiple v-model="item.fileList" @click="upload(index)" @delete="imgChange($event, item)"
              :after-read="afterRead" :before-read="beforeRead" result-type="file" :max-count="1">
            </van-uploader>
          </div>
          <div v-else-if="item.typeText == '单选'" class="check-wrapper">
            <span class="title">{{ item.name }}</span>
            <van-radio-group v-model="item.value" class="check-group" direction="horizontal">
              <van-radio v-for="(val, ind) in item.options" :key="ind + val" :name="val" icon-size="15px"
                class="check-item">
                <span class="check-text">{{ val }}</span>
              </van-radio>
            </van-radio-group>
          </div>
          <div v-else-if="item.typeText == '多选'" class="check-wrapper" style="margin-bottom: 5px;">
            <span class="title">{{ item.name }}</span>
            <van-checkbox-group v-model="item.value" class="check-group check-group-row" direction="horizontal">
              <van-checkbox v-for="(val, ind) in item.options" :key="ind + val" :name="val" icon-size="15px"
                class="check-item">
                <span class="check-text">{{ val }}</span>
              </van-checkbox>
            </van-checkbox-group>
          </div>
          <div v-else-if="item.typeText == '下拉'" class="check-wrapper">
            <span class="title">{{ item.name }}</span>
            <div class="row_option" @click="setOptionCont(item, index)">
              <span class="option-tip" v-if="!item.value">请设置选项</span>
              <span class="option-value" v-else>{{ item.value }}</span>
            </div>
          </div>
          <div v-else-if="item.typeText == '文本域'">
            <van-field :label="item.name" :placeholder="`请选取${item.name}`" disabled v-model="item.value"
              @click="regionBtn(item)"/>
          </div>
          <div v-else-if="item.typeText == '日期'" class="check-wrapper">
            <span class="title">{{ item.name }}</span>
            <van-field style="margin-right: 105px;" :placeholder="`请输入${item.name}`" disabled
              @click="matePppOpen(item,index)" v-model="item.value" />
          </div>
          <van-field v-else :label="item.name" :placeholder="`请输入${item.name}`" v-model="item.value" />
        </div>
      </van-cell-group>
      <div style="padding:0 20px 15px 20px;">
        <van-button type="info" block @click="modifyUserBtn">修改</van-button>
      </div>
    </Popup>
    <selectTime ref="selectTime" />
    <van-popup v-model="matePop" position="bottom" round :style="{ height: '45%'}">
      <van-datetime-picker v-model="currentDate" type="date" title="选择年月日" @cancel="matePop=false"
        @confirm="dateChange" />
    </van-popup>
    <!-- 选择弹窗-->
    <setDetailOption ref="setDetailOption" @change="receiveOption" />
    <!-- 地图 -->
    <van-popup v-model="mapShow" position="bottom" :style="{ height: '100%' }">
      <mapPage ref="popChild" @mapSave="mapSave" @closeMap="closeMap"></mapPage>
    </van-popup>
  </div>
</template>
<script>
  // eslint-disable-next-line no-unused-vars
  import { ContactApi } from '@/api/contact'
  import { Popup, Divider, DatetimePicker, Toast } from 'vant'
  import selectTime from '../../room/components/selectTime'
  import setDetailOption from './setDetailOption'
  import mapPage from './mapPage'
  export default {
    components: {
      Popup, Divider, DatetimePicker, selectTime, setDetailOption, mapPage
    },
    data() {
      return {
        userDetail: false,
        userName: '',
        avatar: '',
        detail: [],
        imgIndex: '',
        checkRowIndex: '',
        mapShow: false,
        currentMapid: '',
        matePop: false,
        selectIndex: 0,
        currentDate: new Date(),
      }
    },
    methods: {
      upload(index) {
        this.imgIndex = index
      },
      imgChange(data, item) {
        item.value = ''
      },
      beforeRead(data) {
        if (data instanceof Array) {
          this.$toast({ position: 'top', message: '最多选择一张图片' })
          return false
        } else {
          return true
        }
      },
      afterRead(data) {
        console.log('this.imgIndex', this.imgIndex)
        const params = new FormData()
        params.append('file', data.file)
        ContactApi.upload(params).then(res => {
          this.detail[10].fileList = [{ url: res.data.fullPath }]
          this.detail[10].value = res.data.path
          console.log('this.detail[this.imgIndex].fileList', this.detail[10].fileList)
        })
      },
      receiveOption(e) {
        this.detail[this.checkRowIndex].value = e
      },
      // 弹窗选项
      setOptionCont(item, index) {
        this.checkRowIndex = index
        this.$refs.setDetailOption.show(item)
      },
      modifyUserBtn() {
        console.log('this.detail', this.detail)
        this.detail.map(item => {
          if (item.typeText == '多选') {
            item.value = item.value.filter(inner => {
              return inner
            })
          }
        })
        ContactApi.updateContactPortrait({
          contactId: this.contactId,
          userPortrait: JSON.stringify(this.detail)
        }).then(res => {
          Toast('修改成功')
          this.userDetail = false
          this.$emit('change', true)
        })
      },
      show(contactDetailInfo) {
        this.userDetail = true
        this.contactId = contactDetailInfo.contactId
        this.userName = contactDetailInfo.name
        this.avatar = contactDetailInfo.avatar
        this.getDetail()
      },
      //  用户详情
      getDetail() {
        console.log('this.detailIndex', this.imgIndex)
        ContactApi.getContactPortrait({ contactId: this.contactId }).then((res) => {
          this.detail = res.data.map(item => {
            if (item.typeText == '图片') {
              if (item.pictureFlag) {
                item.fileList = [{
                  url: item.pictureFlag
                }]
              }
            } else if (item.typeText == '下拉') {
              item.selectOptions = item.options.map(item => {
                return {
                  value: item,
                  text: item
                }
              })
            }
            return item
          })
        })
      },
      dateChange(e) {
        this.matePop = false
        const year = e.getFullYear();
        const month = (e.getMonth() + 1) < 10 ? '0' + (e.getMonth() + 1) : (e.getMonth() + 1);    //js从0开始取
        const date1 = (e.getDate()) < 10 ? '0' + (e.getDate()) : (e.getDate());
        const hour = (e.getHours()) < 10 ? '0' + e.getHours() : e.getHours();
        const minutes = (e.getMinutes()) < 10 ? '0' + e.getMinutes() : e.getMinutes();
        const dateTime = {
          date: year + "-" + month + "-" + date1,
          time: hour + ":" + minutes,
          i: this.index
        }
        this.detail[this.selectIndex].value = dateTime.date
      },
      matePppOpen(item, index) {
        this.matePop = true
        this.selectIndex = index
        // console.log("item",item,this.detail)
      },
      //选取区域
      regionBtn(item) {
        this.currentMapid = item.contactFieldId
        this.mapShow = true
        this.$nextTick(() => {
          this.$refs.popChild.keyword = item.value //修改子组件data
          this.$refs.popChild.address = item.value //修改子组件data
        })

      },
      //地图选址确认事件
      mapSave(row) {
        this.detail.forEach(ele => {
          if (ele.contactFieldId == this.currentMapid) {
            ele.value = row
          }
        });
        this.mapShow = false
      },
      //地图取消
      closeMap(){
        this.mapShow = false
      }
    }
  }
</script>
<style lang="less" scoped>
  .user_page {
    padding: 0 35px 0 35px;
    color: #333;
  }

  .user_flex {
    display: flex;
    justify-content: space-between;
  }

  .data-group {
    padding: 10px 0 30px 0
  }

  .picture-wrapper,
  .check-wrapper {
    align-items: center;
    display: flex;
    padding: 0 16px;

    .title {
      width: 200px;
      font-weight: 400;
      color: #919191;
    }

    .check-text {
      font-weight: 400;
      line-height: 50px;
    }

    .picture {
      width: 128px;
      height: 128px;
      margin-left: 20px;
    }

    .check-group {
      margin-left: -100px;

      .check-item {
        margin-left: 10px;
      }
    }

    .check-group-row {
      margin-left: 50px;
    }

    .dropdown-menu {
      display: flex;
      flex: 1;
      align-items: center;
      margin-left: -20px;
      height: 80px;
    }
  }

  .upload {
    color: #1989fa;
  }

  .row_option {
    margin-left: -100px;
    width: 270px;
    height: 35px;
    border: 1px solid #cccccc;
    //line-height: 55px;
    display: flex;
    align-items: center;
    padding-left: 15px;
    border-radius: 5px;
    cursor: pointer;
  }

  .option-tip {
    color: #919191;
  }

  .option-value {}
</style>