import request from "../plugins/axios";

export function mediumGroup(params) {
    return request({
        url: '/mediumGroup/index',
        method: 'GET',
        params
    })
}
export function groupStore(data) {
    return request({
        url: '/mediumGroup/store',
        method: 'POST',
        data
    })
}
export function medium(data) {
    return request({
        url: '/medium/store',
        method: 'POST',
        data
    })
}
export function mediumUpdate(data) {
    return request({
        url: '/medium/update',
        method: 'PUT',
        data
    })
}
export function mediumShow(params) {
    return request({
        url: '/medium/show',
        method: 'GET',
        params
    })
}
export function mediumList(params) {
    return request({
        url: '/medium/index',
        method: 'GET',
        params
    })
}
export function destroy(data) {
    return request({
        url: '/medium/destroy',
        method: 'DELETE',
        data
    })
}

// 社群互动获取素材列表
export function socialList(params) {
    return request({
        url: '/mediumSocial/index',
        method: 'GET',
        params
    })
}
// 社群互动获取分组列表
export function socialGroupList(params) {
    return request({
        url: '/mediumSocialGroup/index',
        method: 'GET',
        params
    })
}
// 获取互动雷达列表
export function radarIndex(params) {
    return request({
        url: '/radar/index',
        method: 'GET',
        params
    })
}