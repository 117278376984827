<template>
  <div class="all">
    <div class="oneCategory">
      <div
        class="section"
        v-for="(item, index) in oneCategoryList"
        :key="index"
        @click="getTwo(item.id)"
      >
        <div class="icon">
          <img v-if="item.fullIcon != ''" :src="item.fullIcon" alt="" />
        </div>
        <div class="one">{{ item.name }}</div>
      </div>
      <!-- <div class="section">
        <div class="icon">
          <img src="../../assets/images/bkhx.png" alt="" />
        </div>
        <div class="one">一级分类</div>
      </div> -->
    </div>
    <div class="twoCategory">
      <div
        v-for="(res, i) in twoCategoryList"
        :key="i"
        :class="activeColor == true && clickIndex == i ? 'm17' : 'm16'"
        @click="handleClick(i)"
      >
        {{ res.name }}
      </div>
    </div>
    <div class="gray"></div>
    <div v-for="(res, index) in circleList" :key="index">
      <div
        class="circle"
        v-if="res.contentType == 1 || res.contentType == 2"
        @click.stop="skipDetail(res.id, res.shareUrl)"
      >
        <div class="left"><img :src="avatarLogo" alt="" /></div>
        <div class="right">
          <div class="rightTop">
            <div class="rightTopLeft">{{ nickName }}</div>
            <div class="rightTopRight">{{ res.createdAt }}</div>
          </div>
          <div class="rightTitle">
            {{ res.name }}<br />
            {{ text }}
          </div>
          <div class="rightMain">
            <div class="RightMainleft">
              <img v-if="res.fullCover != ''" :src="res.fullCover" alt="" />
            </div>
            <div class="rightMainRig">
              {{ res.summary }}
            </div>
          </div>
          <div class="rightBot">
            <img
              v-for="(img, i) in res.headImg"
              :key="i"
              :src="img"
              alt=""
            /><span>{{ res.shareNum }}人已分享</span>
          </div>
          <div style="margin-top: 9px; margin-bottom: 16px; height: 24px">
            <div class="rightLast" @click.stop="shareLink(res)">分享转发</div>
            <div
              class="record"
              @click.stop="
                () =>
                  $router.push({
                    path: '/visitorRecord/index',
                    query: { id: res.id },
                  })
              "
            >
              访客记录
            </div>
          </div>
        </div>
      </div>
      <div class="circle" v-else>
        <div class="left"><img :src="avatarLogo" alt="" /></div>
        <div class="right">
          <div class="rightTop">
            <div class="rightTopLeft">{{ nickName }}</div>
            <div class="rightTopRight">{{ res.createdAt }}</div>
          </div>
          <div class="rightTitle">
            {{ res.name }}<br />
            {{ text }}
          </div>
          <div class="rightposter">
            <img :src="res.fullPoster" style="margin-top: 10px" alt="" />
            <div class="userInfo" v-show="card_display == 1">
              <div class="left">
                <img class="avatar" :src="userInfo.fullImage" alt="" />
                <div class="info">
                  <div class="name">{{ userInfo.name }}</div>
                  <div class="m12">{{ userInfo.departmentName }}</div>
                  <!-- <div class="m12">{{ userInfo.position }}</div> -->
                  <!-- <div class="m12">{{ userInfo.mobile }}</div> -->
                </div>
              </div>
              <div class="right">
                <img class="qrcode" :src="userInfo.qrCode" alt="" />
              </div>
            </div>
          </div>
          <div class="rightBot">
            <img
              v-for="(img, i) in res.headImg"
              :key="i"
              :src="img"
              alt=""
            /><span>{{ res.shareNum }}人已分享</span>
          </div>
          <div style="margin-top: 9px; margin-bottom: 16px; height: 24px">
            <!-- 不展示名片信息 -->
            <div
              v-if="card_display == 0"
              class="rightLast"
              @click.stop="sharePoster(res)"
            >
              分享转发
            </div>
            <div
              v-else
              class="rightLast"
              @click.stop="createPoster(res.fullPoster, res)"
            >
              分享转发
            </div>
            <div
              class="record"
              @click.stop="
                () =>
                  $router.push({
                    path: '/visitorRecord/index',
                    query: { id: res.id },
                  })
              "
            >
              访客记录
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="set">
      <div class="btn1" @click.stop="skipSet">
        <img src="../../assets/images/electronicCard/circle2.png" alt="" />
        <div class="setting" style="margin-left: 8px">分享设置</div>
      </div>
      <div
        class="btn2"
        @click="
          () => this.$router.push({ path: '/electronicCard/circleHistory' })
        "
      >
        <img src="../../assets/images/electronicCard/circle1.png" alt="" />
        <div class="setting">历史朋友圈</div>
      </div>
    </div>
  </div>
</template>
    <script>
import { category, circleList, shareCallBack } from "../../api/circle";
import { shareToExternalMoments } from "@/utils/wxCodeAuth";
import { vcardIndex } from "@/api/electronicCard";
import { getSet } from "@/api/circle";

// import { ImagePreview } from 'vant';
export default {
  data() {
    return {
      nickName: "",
      avatarLogo: "",
      categoryList: [],
      oneCategoryList: [],
      list: [],
      twoCategoryList: [],
      twoCategoryId: "",
      oneCategoryId: "",
      activeColor: true,
      clickIndex: 0,
      circleList: [],
      circleId: "",
      circleId2: "",
      userInfo: "",
      text: "",
      card_display: 0,
    };
  },
  created() {
    this.getUserInfo();
    this.getCategory();
    this.getCircleList();
    this.getCircleSet();
  },
  methods: {
    getCircleSet() {
      getSet().then((res) => {
        this.card_display = res.data.card_display;
        if (res.data.words_display == 1) {
          this.text = res.data.words;
        }
      });
    },
    getUserInfo() {
      vcardIndex().then((res) => {
        this.userInfo = res.data.employee;
        console.log("emm", this.userInfo);
      });
    },
    shareLink(res) {
      console.log("shareType,id", res);
      this.circleId2 = res.id;
      let shareData = {};
      shareData.type = 3;
      shareData.content = {};
      shareData.content.imageFullPath = res.fullCover ? res.fullCover : "";
      shareData.content.title = res.summary;
      let text1 = "";
      if (this.text != "") {
        text1 = res.name + "\n" + this.text;
      } else {
        text1 = res.name;
      }
      shareData.content.imageLink = res.shareUrl;
      console.log("shareData.content", text1, shareData);
      shareToExternalMoments(text1, shareData).then((res) => {
        console.log("res", res);
        shareCallBack({ id: this.circleId2 }).then(() => {
          console.log("回调");
        });
        this.$message.success("发送成功");
      });
    },
    sharePoster(res) {
      console.log("shareT", res);
      this.circleId = res.id;
      let shareData = {};
      shareData.type = 1;
      shareData.content = {};
      shareData.mediaid = res.mediaId;
      shareData.content.imageFullPath = res.fullPoster;
      let text1 = res.name;
      if (this.text != "") {
        text1 += "\n" + this.text;
      }
      // if (res.mediaId == "") {
      //   shareToExternalMoments(text1).then((res) => {
      //     console.log("res", res);
      //     shareCallBack({ id: this.circleId }).then(() => {
      //       console.log("回调");
      //     });
      //     this.$message.success("发送成功");
      //   });
      //   return;
      // }
      console.log("shareData.content", this.text, shareData);
      shareToExternalMoments(text1, shareData).then((res) => {
        console.log("res", res);
        shareCallBack({ id: this.circleId }).then(() => {
          console.log("回调");
        });
        this.$message.success("发送成功");
      });
    },
    skipDetail(id, url) {
      console.log("url", url);
      if (url != "") {
        location.href = url;
      } else {
        this.$router.push({
          path: "/electronicCard/caijing/detail",
          query: {
            id,
          },
        });
      }
    },
    createPoster(imgurl, res) {
      console.log("imgUrl", imgurl);
      console.log("shareT", res);
      this.circleId = res.id;
      let text1 = res.name;
      if (this.text != "") {
        text1 += "\n" + this.text;
      }
      this.$router.push({
        path: "/poster/create",
        query: { imgurl, text: text1, circleId: this.circleId },
      });
    },
    handleClick(i) {
      this.clickIndex = i;
      this.activeColor = true;
      console.log("this.twoCategoryList[i]", this.twoCategoryList[i]);
      this.twoCategoryId = this.twoCategoryList[i].id;
      this.getCircleList();
    },
    getCircleList() {
      circleList({
        oneCategoryId: this.oneCategoryId,
        twoCategoryId: this.twoCategoryId,
      }).then((res) => {
        this.circleList = res.data.list;
        this.avatarLogo = res.data.logo;
        this.nickName = res.data.nickname;
      });
    },
    getCategory() {
      category().then((res) => {
        this.categoryList = res.data;
        let arr = [];
        this.categoryList.map((res, i) => {
          if (res.pid == 0) {
            console.log(res.pid);
            arr.push(res);
          }
        });
        console.log("一级分", arr);
        this.oneCategoryList = arr;
        if (arr[0].id) {
          this.getTwo(arr[0].id);
        }
        console.log("this.oneCategoryList", this.oneCategoryList);
      });
    },
    getTwo(id) {
      console.log("pid", id);
      this.clickIndex = 0;
      let brr = [];
      this.categoryList.map((res, i) => {
        if (res.pid == id) {
          console.log(1111);
          brr.push(res);
        }
      });
      this.twoCategoryList = brr;
      this.twoCategoryList.unshift({ id: "", name: "全部" });
      console.log("this.twoCategoryList", this.twoCategoryList);
    },
    skipSet() {
      this.$router.push("/electronicCard/circleSet");
    },
  },
};
</script>
    <style lang="less" scoped>
.all {
  height: 100%;
  overflow: auto;
  background-color: #fff;
}
.gray {
  width: 100%;
  height: 10px;
  background: #f2f3f7;
}
.oneCategory {
  display: flex;
  width: 100%;
  height: 75px;
  overflow-x: auto;
  .section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 25%;

    .icon {
      img {
        width: 26px;
        height: 26px;
      }
    }
    .one {
      margin-top: 6px;
      font-size: 12px;
      color: #4a4a4a;
    }
  }
}
.twoCategory {
  height: 40px;
  line-height: 30px;
  margin: 0 auto;
  overflow-x: auto;
  white-space: nowrap;
  margin-left: 22px;
  margin-right: 22px;
  margin-bottom: 12px;
  .m16 {
    display: inline-block;
    margin-right: 8px;
    // width: 25%;
    padding: 0 12px;
    text-align: center;
    height: 28px;
    font-size: 12px;
    font-weight: bold;
    color: #4a4a4a;
    // color: #ffffff;
    line-height: 28px;
    // background-color: #3974c6;
    border-radius: 12px;
  }
  .m17 {
    display: inline-block;
    margin-right: 8px;
    // width: 25%;
    padding: 0 12px;
    text-align: center;
    height: 28px;
    font-size: 12px;
    font-weight: bold;
    // color: #4A4A4A;
    color: #ffffff;
    line-height: 28px;
    background-color: #3974c6;
    border-radius: 12px;
  }
}
.circle {
  width: 94%;
  margin: 0 auto;
  min-height: 100px;
  display: flex;
  justify-content: space-between;
  .left {
    margin-top: 16px;
    width: 10%;
    img {
      width: 34px;
      height: 34px;
    }
  }
  .right {
    margin-top: 16px;
    width: 88%;
    min-height: 100px;
    border-bottom: 1px solid #f1f1f1;
    .rightTop {
      display: flex;
      justify-content: space-between;
      .rightTopLeft {
        font-size: 16px;
        font-weight: bold;
        color: #333333;
      }
      .rightTopRight {
        font-size: 13px;
        color: #888888;
      }
    }
    .rightTitle {
      margin-top: 6px;
      min-height: 24px;
      font-size: 14px;
      color: #4a4a4a;
    }
    .rightMain {
      margin-top: 12px;
      width: 300px;
      height: 62px;
      background: #f3f4f8;
      border-radius: 4px;
      display: flex;
      align-items: center;
      .RightMainleft {
        margin-left: 6px;
        img {
          width: 36px;
          height: 36px;
        }
      }
      .rightMainRig {
        margin: 0 8px;
        font-size: 14px;
        color: #4a4a4a;
      }
    }
    .rightBot {
      margin-top: 12px;
      img:first-child {
        width: 23px;
        height: 23px;
        margin-left: 0;
        border-radius: 50%;
      }
      img {
        width: 23px;
        height: 23px;
        margin-left: -10px;
        border-radius: 50%;
      }
      span {
        display: inline-block;
        margin-left: 9px;
        font-size: 13px;
        color: #888888;
      }
    }
    .record {
      float: right;
      width: 76px;
      padding: 5px;
      text-align: center;
      background: #f2f3f7;
      border-radius: 12px;
      font-size: 12px;
      color: #999999;
    }
    .rightLast {
      float: right;
      margin-left: 10px;
      padding: 5px;
      width: 76px;
      text-align: center;
      background: #f5f7ff;
      border-radius: 12px;
      font-size: 12px;
      color: #486ffb;
    }
  }
}
.rightposter {
  img {
    width: 144px;
    height: 304px;
  }
  .userInfo {
    display: flex;
    justify-content: space-between;
    width: 144px;
    height: 60px;
    .left {
      display: flex;
      width: 80%;
      .avatar {
        width: 30%;
        height: 40px;
      }
      .info {
        margin-left: 4px;
        .name {
          font-size: 10px;
        }
        .m12 {
          font-size: 8px;
        }
      }
    }
    .right {
      width: 20%;
      .qrcode {
        width: 90%;
        height: 30px;
      }
    }
  }
}
.set {
  .btn1 {
    position: fixed;
    right: 2.6%;
    bottom: 240px;
  }
  .btn2 {
    position: fixed;
    right: 2.6%;
    bottom: 160px;
  }
  img {
    width: 56px;
    height: 56px;
  }
  .setting {
    font-size: 10px;
    color: #486ffb;
  }
}
</style>