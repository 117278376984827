<template>
  <div class="index">
    <div class="select">
      <!--      <div style="white-space: nowrap;">-->
      <!--        团队-->
      <!--        <a-icon type="swap"/>-->
      <!--      </div>-->
      <van-search
        v-model="serchVale"
        shape="round"
        placeholder="搜索"
        @search="search"
        @clear="clear"
      />
      <!--          :readonly="!sreenw"-->
      <van-icon
        v-if="sreenw"
        @click="routerPusht"
        style="font-size: 20px"
        name="filter-o"
      />
    </div>

    <van-tabs
      v-model="actives"
      title-active-color="#fff"
      color="#fff"
      background="#3975C6"
      style="flex: 1"
    >
      <van-tab title="客户" v-if="isShow">
        <div class="customer">
          <div v-if="!serchT">
            <div
              class="total"
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
                background-color: #3975c6;
                height: 33px;
                line-height: 33px;
                padding: 0 15px;
                color: #fff;
                font-size: 14px;
              "
            >
              共{{ pages.total }}个客户
              <a
                @click="checkMode = true"
                v-if="!checkMode"
                style="font-size: 14px; color: #fff"
                >批量打标签</a
              >
              <a @click="checkMode = false" v-else style="font-weight: normal"
                >取消</a
              >
            </div>
            <div class="list">
              <van-checkbox-group
                icon-size="15px"
                v-model="resultCustomer"
                ref="checkboxGroup"
              >
                <van-list
                  v-model="loading"
                  :finished="finished"
                  finished-text="没有更多了"
                  @load="onLoad"
                >
                  <div
                    :class="{ choseItem: resultCustomer.indexOf(index) != -1 }"
                    v-for="(item, index) in userList"
                    :key="index"
                  >
                    <div
                      v-if="item.isCorpContact == 1"
                      style="position: relative; z-index: 10"
                    >
                      <img
                        src="../../assets/corpIcon.png"
                        alt=""
                        style="
                          position: absolute;
                          width: 17px;
                          height: 20px;
                          left: 13px;
                          top: 9px;
                        "
                      />
                    </div>
                    <div @click="userDetails(item)" class="list-item">
                      <img
                        @click="routerPush(item)"
                        class="avater"
                        :src="item.avatar"
                        alt=""
                      />
                      <div @click.stop="routerPush(item)" class="mindel">
                        <div class="item">
                          <div style="display: flex">
                            <h4>{{ item.name }}</h4>
                            <span v-if="item.type == 1">@微信</span>
                            <span v-else>@企业微信</span>
                          </div>
                          <div class="bottom">
                            <div
                              :class="{ unset: item.zk }"
                              class="bottom-list"
                            >
                              <div v-for="(i, is) in item.tags" :key="is">
                                {{ i }}
                              </div>
                            </div>
                            <div
                              class="button"
                              v-if="item.tagsl > 18"
                              style="padding: 0 12px"
                            >
                              <div v-if="!item.zk" @click.stop="item.zk = true">
                                展开
                                <van-icon name="arrow-down" />
                              </div>
                              <div v-else @click.stop="item.zk = false">
                                收起
                                <van-icon name="arrow-up" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <img
                        v-if="!checkMode"
                        class="news"
                        src="../../assets/images/newMy.png"
                      />
                      <van-checkbox v-else :name="item"></van-checkbox>
                    </div>
                  </div>
                </van-list>
              </van-checkbox-group>
            </div>
          </div>
          <div v-else>
            <div
              class="total"
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
                background-color: #3975c6;
                height: 33px;
                line-height: 33px;
                padding: 0 15px;
                color: #fff;
                font-size: 14px;
              "
            >
              共{{ serListp.total }}个客户
              <a
                @click="checkMode = true"
                v-if="!checkMode"
                style="font-size: 14px; color: #fff"
                >批量打标签</a
              >
              <a @click="checkMode = false" v-else style="font-weight: normal"
                >取消</a
              >
            </div>
            <div class="list">
              <van-checkbox-group
                icon-size="15px"
                v-model="resultCustomer"
                ref="checkboxGroup"
              >
                <van-list
                  v-model="loading"
                  :finished="finished"
                  finished-text="没有更多了"
                  @load="onLoad"
                >
                  <div
                    :class="{ choseItem: resultCustomer.indexOf(index) != -1 }"
                    style="
                      margin-top: 10px;
                      border-radius: 7px;
                      border: 1px solid transparent;
                    "
                    v-for="(item, index) in serList"
                    :key="index"
                  >
                    <div class="list-item">
                      <img
                        @click="routerPush(item)"
                        class="avater"
                        :src="item.avatar"
                        alt=""
                      />
                      <div @click.stop="routerPush(item)" class="mindel">
                        <div class="item">
                          <div style="display: flex">
                            <h4>{{ item.name }}</h4>
                            <span v-if="item.type == 1">@微信</span>
                            <span v-else>@企业微信</span>
                          </div>
                          <div class="bottom">
                            <div
                              :class="{ unset: item.zk }"
                              class="bottom-list"
                            >
                              <div v-for="(i, is) in item.tags" :key="is">
                                {{ i }}
                              </div>
                            </div>
                            <div
                              class="button"
                              v-if="item.tagsl > 18"
                              style="padding: 0 12px"
                            >
                              <div v-if="!item.zk" @click.stop="item.zk = true">
                                展开
                                <van-icon name="arrow-down" />
                              </div>
                              <div v-else @click.stop="item.zk = false">
                                收起
                                <van-icon name="arrow-up" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <img
                        v-if="!checkMode"
                        class="news"
                        src="../../assets/images/newMy.png"
                      />
                      <van-checkbox v-else :name="index"></van-checkbox>
                    </div>
                    <!-- <div class="bottom">
                      <div :class="{ unset: item.zk }" class="bottom-list">
                        <div v-for="(i, is) in item.tags" :key="is">
                          {{ i }}
                        </div>
                      </div>
                      <div
                        class="button"
                        v-if="item.tagsl > 18"
                        style="padding: 0 12px"
                      >
                        <div v-if="!item.zk" @click="item.zk = true">
                          展开
                          <van-icon name="arrow-down" />
                        </div>
                        <div v-else @click="item.zk = false">
                          收起
                          <van-icon name="arrow-up" />
                        </div>
                      </div>
                    </div> -->
                  </div>
                </van-list>
              </van-checkbox-group>
            </div>
          </div>
        </div>
      </van-tab>
      <van-tab title="客户群" v-if="isShow2">
        <van-list
          v-model="loading"
          :finished="true"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div class="groups">
            <div
              class="total"
              style="
                font-weight: 550;
                display: flex;
                align-items: center;
                justify-content: space-between;
              "
            >
              共{{ baseList.length }}个群聊
            </div>
            <div v-for="(item, index) in baseList" :key="index" class="group">
              <div class="flex-row justify-start info">
                <div class="col-1">
                  <div class="flex-row">
                    <img
                      src="../../assets/images/avatar-room.svg"
                      class="group-cover"
                    />
                    <div class="flex-col align-left">
                      <div class="flex-row">
                        <span class="bold col-1"
                          >{{ item.name }}<span>({{ item.total }})</span></span
                        >
                        <span></span>
                      </div>
                      <div class="group-owner-text">
                        <span>群主：</span>
                        <span class="qw-open-data __qwOpenData__" style="">
                          {{ item.ownerName }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <a-icon
                  :data-clipboard-text="item.name"
                  @click="copy"
                  class="coopy"
                  type="copy"
                  style="font-size: 14.63px; color: #c7c7c7"
                />
              </div>
              <div class="data flex-row">
                <!--                <div class="col-1"><span>{{item.createTime}}</span></div>-->
                <!--                                <div class="col-1"><span><label>今日入群：</label> 0</span> <span><label>今日退群：</label> 0</span></div>-->
                <!--                <span class="clickable mark-tag-btn">打标签</span>-->
              </div>
            </div>
          </div>
        </van-list>
      </van-tab>
    </van-tabs>

    <!-- 多选操作  -->
    <div v-if="checkMode">
      <div class="select-all">
        <van-checkbox v-model="selectAll" icon-size="15px">全选</van-checkbox>
        <span class="length">已选择{{ resultCustomer.length }}个客户</span>
        <van-button
          @click="$refs.customerLabel.$show(tagsValue)"
          style="height: 31px"
          type="info"
          >打标签</van-button
        >
      </div>
    </div>
    <!--  添加标签  -->
    <addTags ref="tags" />
    <van-tabbar v-model="active" class="tab">
      <van-tabbar-item replace to="/">
        <span>首页</span>
        <template #icon="props">
          <img v-if="props.active" src="../../assets/images/home-active.png" />
          <img v-else src="../../assets/images/home.png" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/customer" icon="search" v-show="isShow3">
        <span>客户</span>
        <template #icon="props">
          <img
            v-if="props.active"
            src="../../assets/images/customer-active.png"
          />
          <img v-else src="../../assets/images/customer.png" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item
        replace
        to="/xiaoban/index"
        icon="setting-o"
        v-permission="'system:dhXa3'"
      >
        <span>AI助手</span>
        <template #icon="props">
          <img v-if="props.active" src="../../assets/index/aiActive.png" />
          <img v-else src="../../assets/index/ai.png" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/tool/index">
        <span>营销台</span>
        <template #icon="props">
          <img
            v-if="props.active"
            src="../../assets/index/operate-active.png"
          />
          <img v-else src="../../assets/index/operate.png" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/my" icon="setting-o">
        <span>我的</span>
        <template #icon="props">
          <img v-if="props.active" src="../../assets/images/my-active.png" />
          <img v-else src="../../assets/images/my.png" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
    <customerLabel ref="customerLabel" @change="userTagsChose" />
  </div>
</template>

<script>
import addTags from "./components/addTags";
import { judgePermission } from "@/utils/index";
import {
  batchCreateContactTag,
  contactIndex,
  workRoom,
} from "../../api/customer";
import customerLabel from "../channelCode/components/customerLabel";
import Clipboard from "clipboard";

export default {
  components: {
    addTags,
    customerLabel,
  },
  data() {
    return {
      isShow: false,
      isShow2: false,
      isShow3: true,
      // 文字
      message: "",
      // tbas切换
      tabsChose: 0,
      actives: 0,
      active: 1,
      // 搜索条件
      serchVale: "",
      // 客户
      customerList: [
        {
          aa: [
            "传是额发v二位",
            "就玷污清白",
            "dwqhgqwbq",
            "的武器以前有行情不好",
          ],
          zk: false,
        },
        {
          aa: [
            "传是额发v二位",
            "就玷污清白",
            "dwqhgqwbq",
            "的武器以前有行情不好",
          ],
          zk: false,
        },
      ],
      resultCustomer: [],
      checkMode: false,
      // 全选
      selectAll: "",
      // 列表加载
      loading: false,
      finished: false,
      page: 1,
      userList: [],
      pages: {
        perPage: "",
        total: "",
        totalPage: "",
      },
      tagsValue: [],

      dataLiS: {
        page: "",
        perPage: "",
      },
      serList: [],
      serchT: false,
      serListp: {},

      // 客户群
      baseList: [],
      sreenw: true,
    };
  },
  watch: {
    selectAll(e) {
      if (e) {
        this.$refs.checkboxGroup.toggleAll(true);
      } else {
        this.$refs.checkboxGroup.toggleAll();
      }
    },
    actives(e) {
      if (this.actives == 1) {
        this.sreenw = false;
        this.serchVale = "";
      } else {
        this.sreenw = true;
      }
    },
    serchVale() {
      if (!this.serchVale) {
        this.clear();
      }
    },
  },
  created() {
    this.isShow = judgePermission("system:17nr5");
    this.isShow2 = judgePermission("system:zSTgX");
    this.isShow3 =
      judgePermission("system:wrnTo") || judgePermission("system:zSTgX");
    this.customerBase();
    //表示是从链接跳转过来
    if (this.$route.query.content) {
      this.dataLiS = JSON.parse(this.$route.query.content);
    }
  },
  methods: {
    routerPush(item) {
      if (judgePermission("system:PB0ch") == true) {
        this.$router.push({
          path: "/contact/detail",
          query: {
            contactId: item.id,
          },
        });
      }
    },
    routerPusht() {
      this.$router.push("/contact/screen");
    },
    userTagsChose(data) {
      const tagIds = data.map((i) => {
        return i.id;
      });
      const contactIds = this.resultCustomer.map((i) => i.id);
      const datas = {
        tagIds,
        contactIds,
      };
      batchCreateContactTag(datas).then((res) => {
        this.$message.success("操作成功");
        this.selectAll = [];
        this.checkMode = false;
      });
    },
    // 列表加载
    onLoad() {
      this.dataLiS.page = this.page;
      this.dataLiS.perPage = 10;
      this.dataLiS.name = this.serchVale;
      //表示从链接跳转进来并存在isNotTag
      if (this.$route.query.isNotTag) {
        this.dataLiS.isNotTag = this.$route.query.isNotTag;
      }
      contactIndex(this.dataLiS).then((res) => {
        res.data.list.forEach((i) => {
          i.zk = false;
          i.tagsl = String(i.tags).length;
          i.createTime = i.createTime.split(" ")[0];
        });

        this.userList.push(...res.data.list);
        this.pages = res.data.page;
        /*if(res.data.list.length < 10){
          this.finished = true
          this.loading = true
        }else{
          this.page++
          this.finished = false
          this.loading = false
        }*/
        this.finished = true;
        this.loading = true;
      });
    },
    customerBase(e) {
      workRoom({ name: e }).then((res) => {
        this.baseList = res.data.list;
      });
    },
    search() {
      if (this.actives == 0) {
        this.dataLiS.page = this.page;
        this.dataLiS.perPage = 10;
        this.dataLiS.name = this.serchVale;
        this.serchT = true;
        contactIndex(this.dataLiS).then((res) => {
          res.data.list.forEach((i) => {
            i.zk = false;
            i.tagsl = String(i.tags).length;
            i.createTime = i.createTime.split(" ")[0];
          });

          this.serListp = res.data.page;
          this.serList = res.data.list;
        });
      } else {
        this.customerBase(this.serchVale);
      }
    },
    clear() {
      if (this.actives == 0) {
        this.serchT = false;
      } else {
        this.customerBase();
      }
    },
    copy(i) {
      var clipboard = new Clipboard(".coopy");
      clipboard.on("success", (e) => {
        this.$message.success("复制成功");
        //  释放内存
        clipboard.destroy();
      });
    },
    userDetails(e) {},
  },
};
</script>

<style lang="less" scoped>
/deep/ .van-tabbar-item {
  color: #c1c9d0;
}
/deep/ .van-tabbar-item--active {
  color: #3975c6 !important;
}
/deep/ .van-tabbar-item {
  color: #c1c9d0;
}
/deep/ .van-tab {
  font-size: 16px;
  color: #fff;
}
/deep/ .van-tabs {
  margin-top: -4px;
}
/deep/ .van-tabs__nav--line {
  padding-bottom: 0px;
}
/deep/ .van-tabs__line {
  bottom: 1px;
}
/deep/.van-tabbar {
  box-sizing: content-box !important;
  margin: 0 auto;
  width: 100%;
  max-width: 500px;
  left: none;
}
.van-tabbar--fixed {
  left: unset !important;
}
/deep/ .select .van-nav-bar__title {
  color: #fff !important;
}

/deep/ .select .van-field__body {
  color: #fff;
}

/deep/ .select .van-search {
  background: #3975c6;
  width: 100%;
}

/deep/ .select .van-search__content {
  background: rgba(255, 255, 255, 0.3);
  color: #fff;
}

/deep/ .select .van-icon-search {
  color: #fff;
}
.index {
  font-size: 16px;
  height: 100%;
  background: #f1f2f5;
  display: flex;
  flex-direction: column;
  p {
    margin: 0 !important;
  }

  .fs14 {
    opacity: 0.7;
    font-weight: 400;
    font-size: 16px;
  }

  .fs16 {
    font-size: 14px;
  }

  .fs12 {
    font-size: 12px;
  }

  .fs24 {
    font-size: 24px;
  }

  .select {
    display: flex;
    align-items: center;
    background: #3975c6;
    padding: 12px 16px 0 16px;
    box-sizing: border-box;
    color: #fff;
  }

  .bottom {
    margin-left: -6px;
    .bottom-list {
      background: #fff;
      padding: 0 5px 0 0;
      font-size: 11px;
      max-height: 30px;
      overflow: hidden;
      padding-bottom: 10px;

      div {
        background: #f7f7f7;
        border: 1px solid #e9e9e9;
        display: inline-block;
        padding: 2px 3px;
        border-radius: 6px;
        margin: 0px 5px;
        margin-bottom: 9px;
        font-size: 11px;
      }
    }

    .button {
      padding: 0px 12px;
      background: #fff;
      padding-bottom: 5px !important;

      div {
        font-size: 11px;
        color: #8db1db;
      }
    }

    .unset {
      max-height: unset;
    }

    border-radius: 0 0 7px 7px;
    overflow: hidden;
  }

  .customer {
    img {
      width: 22px;
      height: 22px;
    }
    // padding: 0px 16px;
    padding-bottom: 51px;
  }

  .list {
    border-radius: 20px;
    .choseItem {
      border: 1px solid #1890ff;
    }
    .list-item {
      display: flex;
      // align-items: center;
      justify-content: space-between;
      background: #fff;
      padding: 10px 15px;
      border: 1px solid #f1f1f1;
      .mindel {
        flex: 1;
        margin-left: 15px;
      }
      .avater {
        position: relative;
        z-index: 1;
        width: 46px;
        height: 46px;
        border-radius: 50%;
      }

      .item {
        //margin-left: -87px;

        div:nth-child(1) {
          h4 {
            font-size: 14px;
            font-weight: bold;
            max-width: 168px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          span {
            font-size: 11px;
            color: #5ec75d;
            margin-left: 5px;
          }
        }

        div:nth-child(2) {
          font-size: 11px;
          color: #6a6a6a;

          div:nth-child(1) {
            // display: flex;

            img {
              width: 20px;
              height: 20px;
              border-radius: 50%;
              margin-right: 5px;
            }
          }
        }
      }

      .news {
        width: 19px;
        height: 17px;
        margin-top: 16px;
      }
    }
  }

  .groups {
    padding: 0 16px 25px;
    margin-top: 6px;
    .group {
      margin-top: 10px;
      background: #f9f9f9;
      padding: 12.5px 12.5px 6.3px 14.584px;

      .info {
        padding-bottom: 12.5px;
        border-bottom: 1.03px solid #f2f2f2;

        .col-1 {
          min-width: 0;
        }
      }

      .flex-row.justify-start {
        -webkit-box-align: start;
        align-items: flex-start;
      }

      .flex-row {
        -webkit-box-pack: start;
        -webkit-box-align: center;
        align-items: center;
        display: -webkit-box;
        display: flex;
        justify-content: flex-start;
        padding: 0;

        .col-1 {
          -webkit-box-flex: 1;
          flex: 1;

          .flex-row {
            -webkit-box-pack: start;
            -webkit-box-align: center;
            align-items: center;
            display: -webkit-box;
            display: flex;
            justify-content: flex-start;
            padding: 0;

            .flex-col {
              padding-left: 8.334px;
              -webkit-box-flex: 1;
              flex: 1;
              min-width: 0;
              padding-right: 19.793px;

              .flex-row {
                max-width: 100%;

                .bold {
                  font-size: 14px;
                  font-weight: 600;
                  color: #333;
                  //line-height: 1.282125rem;
                  min-width: 0;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  word-break: break-all;
                  -webkit-box-flex: 1;
                  flex: 1;
                }

                span {
                  font-size: 14px;
                  color: rgba(0, 0, 0, 0.45);
                }
              }

              .group-owner-text {
                font-size: 14px;
                color: rgba(0, 0, 0, 0.3);

                .qw-open-data {
                  word-break: break-all;
                }
              }
            }

            .align-left {
              -webkit-box-align: start;
              align-items: flex-start;
            }

            img {
              width: 38px;
              height: 38px;
            }
          }
        }
      }

      .data {
        margin-top: 5.209px;

        .col-1 {
          span {
            color: rgba(0, 0, 0, 0.65);
            font-size: 14px;

            label {
              color: rgba(0, 0, 0, 0.45);
            }
          }

          span:last-child {
            margin-left: 17.709px;
          }
        }

        .mark-tag-btn {
          background: #1890ff;
          border-radius: 4px;
          font-size: 14px;
          color: #fff;
          height: 25px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 1.042px 8.334px;
          display: inline-block;
          cursor: pointer;
          user-select: none;
        }
      }
    }
  }
  .select-all {
    position: fixed;
    width: 100%;
    display: flex;
    align-items: center;
    background: #fff;
    padding: 8px 10px;
    justify-content: space-between;
    bottom: 84px;
    max-width: 500px;

    .length {
      color: rgba(0, 0, 0, 0.45);
      margin-left: -90px;
    }
  }
}
</style>
