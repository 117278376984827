<template>
  <div class="index">
    <div class="search">
      <van-search
        v-model="searchVal"
        shape="round"
        show-action
        placeholder="请输入工单名称或客户名称"
        @search="onSearch"
      >
        <template #action>
          <div @click="onSearch">
            <img
              class="searchIcon"
              src="../../assets/workOrder/search.png"
              alt=""
            />
          </div>
        </template>
      </van-search>
    </div>
    <div class="select">
      <van-dropdown-menu active-color="#1989fa">
        <van-dropdown-item v-model="selectVal1" :options="option1" />
        <van-dropdown-item v-model="selectVal2" :options="option2" />
      </van-dropdown-menu>
    </div>
    <van-loading v-if="isGet == false" style="text-align: center" size="24px"
      >加载中...</van-loading
    >
    <div class="list" v-else>
      <div class="list" v-if="listData.length > 0">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div class="main" v-for="(item, index) in listData" :key="index">
            <div class="content" @click.stop="skipDetail(item.id)">
              <div class="top">
                <div class="topLeft">{{ item.name }}</div>
                <div class="topRight" v-if="item.status == 1">待抢单</div>
              </div>
              <div class="address">
                <div class="common">
                  经营地址
                  {{ item.addr }}
                </div>
                <div
                  class="icon2"
                  v-if="item.status == 1"
                  @click.stop="handleRob(item.id)"
                >
                  <img
                    class="examine2"
                    src="../../assets/workOrder/rob.png"
                    alt=""
                  />
                </div>
              </div>
              <div class="address">
                <div class="common">客户名称 {{ item.contactName }}</div>
              </div>
              <div class="address">
                <div class="common" style="width: 60%">
                  发起时间 {{ item.createdAt }}
                </div>
                <div class="name">发起人 {{ item.create_employee_name }}</div>
              </div>
            </div>
          </div>
        </van-list>
        <div style="height: 10px"></div>
      </div>
      <van-empty
        v-else
        class="custom-image"
        :image="require('../../assets/workOrder/empty.png')"
        description="暂无工单"
      />
    </div>
    <van-dialog
      v-model="showPopup"
      @confirm="$router.push('/workOrder/index')"
      @cancel="showPopup=false,reset()"
      confirmButtonText="我的工单"
      cancelButtonText="继续抢单"
      show-cancel-button
      confirmButtonColor="#3974C6"
    >
    <div @click="showPopup=false,reset()" style="text-align:right;width:95%;padding-top:6px"><img class="closeBtn" src="../../assets/workOrder/close.png" alt=""></div>
      <div class="popupContent">抢单成功！</div>
    </van-dialog>
  </div>
</template>
<script>
import { orderList, robOrder } from "@/api/workOrder";
import { getEmployeeList } from "@/api/common";
import { getCurrentTime, getMonday, getfirstDayOfMonth } from "@/utils/index";
export default {
  data() {
    return {
      showPopup: false,
      searchVal: "",
      selectVal1: "",
      selectVal2: "",
      listData: [],
      loading: false,
      finished: false,
      page: 1,
      isGet: false,
      length: 0,
      option1: [{ text: "全部(发起人)", value: "" }],
      option2: [{ text: "全部", value: "" }],
      EmployeeData:[]
    };
  },
  created() {
    this.getList();
    this.getEmployeeData()
    var time = getCurrentTime();
    this.option2.push({ text: "今日", value: time });
    var str = JSON.stringify(getMonday());
    var monday = str.substring(1, 11) + " " + "00" + ":" + "00" + ":" + "01";
    this.option2.push({ text: "本周", value: monday });
    var firstDay = getfirstDayOfMonth() + " " + "00" + ":" + "00" + ":" + "02";
    this.option2.push({ text: "本月", value: firstDay });
    console.log("dfdf", firstDay);
  },
  watch: {
    selectVal1() {
      this.reset();
    },
    selectVal2() {
      this.reset();
    },
  },
  methods: {
    getList() {
      orderList({
        status: 1,
        employee_id: this.selectVal1,
        startTime: this.selectVal2,
        search: this.searchVal,
        page: this.page,
        perPage: 20,
      }).then((res) => {
        this.isGet = true;
        this.listData.push(...res.data.list);
        this.length = res.data.page.total;
        // 加载状态结束
        this.loading = false;
        // 数据全部加载完成
        if (this.listData.length >= res.data.page.total) {
          this.finished = true;
        } else {
          this.finished = false;
        }
      });
    },
    onLoad() {
      this.page++;
      this.getList();
    },
    reset() {
      this.isGet = false;
      this.finished = false;
      this.listData = [];
      this.page = 1;
      this.getList();
    },
    onSearch() {
      this.reset();
    },
    handleRob(id) {
      robOrder({ id }).then((res) => {
        this.showPopup = true;
      });
    },
    skipDetail(id) {
      this.$router.push({ path: "/workOrder/detail", query: { id } });
    },
    getEmployeeData(){
      getEmployeeList().then((res) => {
        res.data.map(res=>{
          this.option1.push({text:res.name,value:res.id})
        })
        console.log('fdf',this.option1)
      // option1: [{ text: "全部(发起人)", value: 1 }],
      });
    }
  },
};
</script>
<style lang="less" scoped>
/deep/ .van-search {
  background-color: #3975c6;
}
.index {
  min-height: 100%;
  background-color: #f2f3f7;
}
.search {
  width: 100%;
  height: 64px;
  background: #3975c6;
  padding-top: 5px;
  .searchIcon {
    width: 25px;
    height: 25px;
  }
}
.skip {
  margin-top: -2px;
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: 90px;
  background: #3975c6;

  .st {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .icon1 {
      width: 26px;
      height: 26px;
    }
    .name {
      padding-top: 4px;
      font-size: 12px;
      font-weight: bold;
      color: #ffffff;
    }
  }
}
.list {
  background-color: #f2f3f7;
  overflow: auto;
  height: calc(100% - 74px);
  .main {
    width: 93%;
    min-height: 157px;
    background: #ffffff;
    border-radius: 5px;
    margin: 0 auto;
    margin-top: 14px;
    .content {
      width: 94%;
      margin: 0 auto;
      overflow: hidden;

      .top {
        display: flex;
        height: 56px;
        line-height: 56px;
        justify-content: space-between;
        border-bottom: 1px solid #f1f1f1;
        margin-bottom: 5px;
        .topLeft {
          width: 248px;
          height: 56px;
          line-height: 56px;
          font-size: 16px;
          font-weight: bold;
          color: #000000;
          letter-spacing: 1px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .topRight {
          height: 56px;
          line-height: 56px;
          font-size: 12px;
          font-weight: 500;
          color: #3975c6;
        }
      }
      .address {
        display: flex;
        align-items: center;
        min-height: 28px;
      }
      .common {
        font-size: 12px;
        color: #9b9b9b;
        display: inline-block;
        width: 90%;
      }
      .icon {
        display: inline-block;
        width: 10%;
        text-align: right;
      }
      .examine {
        width: 17px;
        height: 20px;
      }
      .name {
        width: 40%;
        text-align: right;
        font-size: 12px;
        color: #9b9b9b;
        display: inline-block;
      }
    }
  }
}
.icon2 {
  display: inline-block;
  width: 10%;
  text-align: right;
}
.examine2 {
  width: 23px;
  height: 23px;
}
.popupContent {
  font-size: 17px;
  font-weight: 500;
  color: #000000;
  text-align: center;
  margin: 10px 0 20px 0;
}
.closeBtn{
  width: 13px;
  height: 13px;
}
</style>
