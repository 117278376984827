<template>
  <div class="contact-page">
    <van-tabs v-model="tabActive" color="#fff" background="#3975C6">
      <van-tab title="客户详情">
        <detailpage></detailpage>
      </van-tab>
      <van-tab title="生日提醒" :dot="dotStatus" v-if="isStringInArray('system:6l5qC')">
        <birthdays></birthdays>
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
  import detailpage from './detailPage.vue'
  import birthdays from './birthdaysPage.vue'
  import { birthdayRemind } from "@/api/festival";
  import store from "@/store";
  export default {
    components: {
      detailpage,
      birthdays
    },
    data() {
      return {
        tabActive: 0,
        dotStatus: false,
      }
    },
    created() {
      this.getData()
    },
    methods: {
      isStringInArray(str) {
      return store.getters.permission.includes(str);
    },
      getData() {
        birthdayRemind({ contactId: this.$route.query.contactId }).then((res) => {
          if (res.code == 200 && res.data.contact) {
            this.dotStatus = true
          }
        })
      },
    }
  }
</script>
<style lang="less" scoped>
  /deep/ .van-tabbar-item--active {
    color: #3975C6 !important;
  }
  /deep/ .van-tab {
    font-size: 16px;
    color: #fff;
  }
  /deep/ .van-tabs {
    margin-top: -4px;
  }
  /deep/ .van-tabs__nav--line {
    padding-bottom: 0px;
  }
  /deep/ .van-tabs__line {
    bottom: 1px;
  }
  /deep/ .van-nav-bar__left {
    font-size: 16px;
  }
  /deep/ .van-nav-bar__content {
    background: #2b63cd;
    color: #fff;
  }
  /deep/ .van-nav-bar .van-icon {
    color: #fff;
  }
  /deep/ .van-nav-bar__text {
    color: #fff;
  }
  /deep/ .van-nav-bar__title {
    color: #fff;
  }
</style>