import request from "@/utils/request";

/**
 * 客户
 */
export class ContactApi {
  // 客户详情基本信息
  static show(params) {
    return request({
      url: "/workContact/show",
      method: "get",
      params: params,
    });
  }

  // 客户id
  static detail(params) {
    return request({
      url: "/workContact/detail",
      method: "get",
      params: params,
    });
  }

  // 编辑客户基本信息
  static update(params) {
    return request({
      url: "/workContact/update",
      method: "put",
      data: params,
    });
  }

  // 用户画像
  static getContactPortrait(params) {
    return request({
      url: "/contactFieldPivot/index",
      method: "get",
      params: params,
    });
  }

  // 修改用户画像
  static updateContactPortrait(params) {
    return request({
      url: "/contactFieldPivot/update",
      method: "put",
      data: params,
    });
  }

  // 所有标签
  static allTag(params) {
    return request({
      url: "/workContactTag/allTag",
      method: "get",
      params: params,
    });
  }

  // 所有标签分组
  static workContactTagGroup(params) {
    return request({
      url: "/workContactTagGroup/index",
      method: "get",
      params: params,
    });
  }
  // 获取支行标签列表
  static businessTag(params) {
    return request({
      url: "/contactStrategyTag/index",
      method: "get",
      params: params,
    });
  }
  // 上传
  static upload(params) {
    return request({
      url: "/common/upload",
      method: "post",
      data: params,
    });
  }

  // 企业标签添加
  static createContactTag(params) {
    return request({
      url: "/workContact/createContactTag",
      method: "post",
      data: params,
    });
  }

  // 个人标签添加
  static storeApi(params) {
    return request({
      url: "/workContact/contactTag/store",
      method: "post",
      data: params,
    });
  }

  // 客户画像-个人标签-列表
  static indexApi(params) {
    return request({
      url: "/workContact/contactTag/index",
      method: "get",
      params,
    });
  }

  // 客户画像-个人标签-删除
  static destroyApi(params) {
    return request({
      url: "/workContact/contactTag/destroy",
      method: "delete",
      data: params,
    });
  }

  // 客户画像-个人标签-客户打标签
  static storeContactTagApi(params) {
    return request({
      url: "/workContact/contactTag/storeContactTag",
      method: "post",
      data: params,
    });
  }

  // 客户画像-模板拉群-员工客户列表
  static employeeContact(params) {
    return request({
      url: "/workContact/employeeContact",
      method: "get",
      params,
    });
  }

  // 企业标签-删除
  static deleteContactTagApi(params) {
    return request({
      url: "/workContact/deleteContactTag",
      method: "delete",
      data: params,
    });
  }

  // 个人标签-删除
  static employeeTagDeleteApi(params) {
    return request({
      url: "/contactEmployeeTag/employeeTagDelete",
      method: "delete",
      data: params,
    });
  }
}
// 获取雷达列表
export function getRadarList(params) {
  return request({
    url: '/radar/getRecordList',
    method: 'get',
    params: params
  })
}