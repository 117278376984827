<template>
  <div class="index">
    <!-- <van-nav-bar
        left-text="一键拉群"
        left-arrow
        @click-left="onClickLeft"
    /> -->
    <div class="content">
      <!-- <van-notice-bar
          style="font-size: 14px;margin-bottom: 10px;"
          wrapable
          :scrollable="false"
          text=" 筛选客户后可一键给选择的客户拉群，因企业微信限制，仅可拉取“未流失” 的客户，一个群内最多邀请39个客户"
      /> -->
      <div class="top">
        筛选客户后可一键给选择的客户拉群，因企业微信限制，仅可拉取“未流失”
        的客户，一个群内最多邀请39个客户
      </div>
      <van-cell
        class="tabs"
        title="企业标签"
        is-link
        value="请选择企业标签"
        @click="$refs.customerLabel.$show(tags, tagsValueEdit)"
      >
        <div v-if="tags.length >= 0">
          <van-tag
            style="margin: 1px 2px"
            v-for="i in tags"
            plain
            size="medium"
            type="primary"
          >
            {{ i.name }}
          </van-tag>
        </div>
        <div v-if="tags.length <= 0">请选择企业标签</div>
      </van-cell>

      <van-cell
        is-link
        title="添加时间"
        :value="addTimeData || '请选择时间'"
        @click="addTimeShow = true"
      />
      <van-calendar
        v-model="addTimeShow"
        color="#1989fa"
        type="range"
        @confirm="onAddConfirm"
      />

      <van-cell
        is-link
        title="添加渠道"
        :value="channel.name || '请选择渠道'"
        @click="channelShow = true"
      />

      <van-cell
        is-link
        title="客户性别"
        :value="gender.name || '请选择性别'"
        @click="genderShow = true"
      />

      <p class="hint">满足条件的客户数 ：{{ count || 0 }}</p>

      <div class="button-bottom">
        <van-button @click="clear" style="width: 45%" block type="default"
          >清空筛选</van-button
        >
        <van-button @click="getData" style="width: 45%" block type="info"
          >查看客户</van-button
        >
      </div>
    </div>

    <!--  标签  -->
    <customerLabel ref="customerLabel" @change="choseTags" />
    <!--  选择渠道  -->
    <van-action-sheet
      v-model:show="channelShow"
      :actions="qcode"
      cancel-text="取消"
      close-on-click-action
      @select="channeChose"
      @cancel="channeonCancel"
    />
    <!--  性别  -->
    <van-action-sheet
      v-model:show="genderShow"
      :actions="genderD"
      cancel-text="取消"
      close-on-click-action
      @select="genderChose"
      @cancel="genderonCancel"
    />
  </div>
</template>

<script>
import customerLabel from "../channelCode/components/customerLabel";
import { filterContact } from "../../api/roomTemplate";

export default {
  components: {
    customerLabel,
  },
  data() {
    return {
      // 企业标签
      shows: false,
      tags: [],
      tagsValueEdit: false,
      // 添加时间
      addTimeShow: false,
      addTimeData: "",
      // 添加渠道
      channel: {
        name: "",
        value: "",
      },
      qcode: [
        { chose: true, value: 2, name: "搜索手机号" },
        { chose: false, value: 5, name: "手机通讯录" },
        { chose: false, value: 8, name: "安装第三方应用时添加的客服人员" },
        { chose: false, value: 202, name: "管理员/负责人分配" },
        { chose: false, value: 1003, name: "裂变引流" },
      ],
      starTime: "",
      endTime: "",
      // 选择性别
      gender: {
        name: "",
        value: "",
      },
      genderShow: false,
      genderD: [
        {
          name: "男性",
          value: 1,
        },
        {
          name: "女性",
          value: 2,
        },
        {
          name: "未知",
          value: 0,
        },
      ],
      //  渠道
      channelShow: false,

      count: 0,
    };
  },
  watch: {},
  methods: {
    onClickLeft() {
      this.$router.back();
    },
    // 企业标签
    choseTags(data) {
      this.tags = data;
      this.screen();
    },
    tabsChose(e) {},
    tagsClose(i) {},
    // 添加时间
    formatDate(date) {
      const year = date.getFullYear();
      const month =
        date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : "0" + (date.getMonth() + 1);
      const day = date.getDate() > 9 ? date.getDate() : "0" + date.getDate();
      return year + "-" + month + "-" + day;
    },
    onAddConfirm(date) {
      const [start, end] = date;
      this.addTimeShow = false;
      this.starTime = this.formatDate(start);
      this.endTime = this.formatDate(end);
      this.addTimeData = `${this.formatDate(start)} - ${this.formatDate(end)}`;
      this.screen();
    },
    // 渠道
    channeChose(e) {
      this.channel = e;
      this.screen();
    },
    channeonCancel(e) {
      this.channelShow = false;
    },
    genderChose(e) {
      this.gender = e;
      this.screen();
    },
    genderonCancel(e) {
      this.genderlShow = false;
    },

    clear() {
      this.tags = [];
      this.addTimeData = "";
      this.starTime = "";
      this.endTime = "";
      this.channel = {
        name: "",
      };
      this.gender = {
        name: "",
      };
      this.screen();
    },

    getData() {
      this.$router.push({
        path: "/roomTemplatePull/create",
        query: {
          tags: this.tags.map((i) => {
            return i.id;
          }),
          startTime: this.starTime,
          endTime: this.endTime,
          addWay: this.channel.value,
          gender: this.gender.value,
        },
      });
    },

    screen() {
      filterContact({
        tags: this.tags.map((i) => {
          return i.id;
        }),
        startTime: this.starTime,
        endTime: this.endTime,
        addWay: this.channel.value,
        gender: this.gender.value,
      }).then((res) => {
        this.count = res.data.count;
      });
    },
  },
  created() {
    this.screen();
  },
};
</script>

<style lang="less" scoped>
/deep/ .van-popup--bottom{
  left: 0 !important;
}
/deep/ .van-cell {
  font-size: 14px;
  flex-direction: column;
}
/deep/.van-nav-bar__content {
  background: #2b63cd;
  color: #fff;
}
/deep/.van-nav-bar .van-icon {
  color: #fff;
}
/deep/ .van-cell__right-icon {
  width: 7%;
  position: absolute;
  top: 22px;
  right: 5px;
}
/deep/ .van-cell__value {
  width: 90%;
  text-align: left;
}
/deep/.van-nav-bar__text {
  color: #fff;
}
/deep/.van-nav-bar__title {
  color: #fff;
}
/deep/ .van-tabs__line {
  bottom: 9px;
  margin-top: 0;
}

/deep/ .van-tabs__nav--line {
  padding-bottom: 1px;
}

/deep/ .van-tab__text {
  margin-bottom: 10px;
}

/deep/ .van-tab {
  background: #ffffff;
}

/deep/ .van-tabs__nav {
  background: #f1f2f5;
}

/deep/ .van-empty__image img {
  object-fit: contain;
}

/deep/ .van-dropdown-menu__bar {
  height: 36px;
}

/deep/ .van-ellipsis {
  font-size: 15px;
}

/deep/ .van-action-sheet__description {
  font-size: 16px;
  color: #444;
}

/deep/ .tabs .van-cell__title {
  width: 56px !important;
  flex: none !important;
  white-space: nowrap;
}

.index {
  font-size: 16px;
  height: 100%;
  background: #f2f4f8;

  p {
    margin: 0 !important;
  }

  .fs17 {
    font-size: 15px;
  }

  .fs13 {
    font-size: 14px;
  }

  .fs14 {
    font-size: 14px;
  }

  .content {
    background: #f2f4f8;
    min-height: 92%;
    //padding-top: 5px;
    padding: 10px 10px;
    .hint {
      margin-top: 12px !important;
      padding: 12.5px;
      background: #f7fbff;
      border: 1px solid #cce9ff;
      font-size: 14px;
      color: #333;
    }
    .button-bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      /* margin-top: 50px; */
      position: fixed;
      bottom: 10px;
      width: 100%;
      left: 0;
      padding: 0 10px;
    }
    img {
      width: 22px;
      height: 22px;
    }
  }
}
.top {
  width: 95%;
  height: 33px;
  margin: 0 auto;
  margin-bottom: 10px;
  font-size: 12px;
  color: #9b9b9b;
  line-height: 17px;
}
</style>
